import React from "react";
import { useState } from "react";
import styles from "./SquareButton.module.css";

const SquareButton = ({
  onClick,
  children,
  btnText,
  highlighted = false,
  textOnChange,
  bdColor = "var(--crt-fg-100)",
  color = "var(--crt-fg-100)",
  bgColor = "var(--crt-bg-100)",
}) => {
  const [textChanging, setTextChanging] = useState(false);

  const clickHandler = () => {
    if (textOnChange) {
      setTextChanging(true);
      setTimeout(() => {
        setTextChanging(false);
      }, 1000);
    }
    onClick();
  };

  return (
    <div
      onClick={clickHandler}
      className={`${styles.button} ${btnText ? styles.wide : ""} ${highlighted ? styles.highlighted : ""} ${textChanging ? styles.changing : ""}`}
      style={{ borderColor: bdColor, color: color, backgroundColor: bgColor }}
    >
      {!textChanging && (
        <div className={styles.content}>
          {children}
          {btnText}
        </div>
      )}
      {textOnChange && (
        <div className={styles.text} style={{ backgroundColor: color, color: bgColor }}>
          {textOnChange}
        </div>
      )}
    </div>
  );
};

export const CollapseButton = () => {};

export default SquareButton;
