import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapActions } from "../../../store/map-slice";
import styles from "../Map.module.css";
import { getZIndex } from "../../../utils/map";

const SearchMarker = ({ idx, position }) => {
  const dispatch = useDispatch();
  const mapData = useSelector((state) => state.map)
  const [zIndex, setZIndex] = useState(0);
  
  useEffect(() => {
    console.log("mapdata center", mapData.center)
    setZIndex(getZIndex(position, mapData.center, idx))
  }, [mapData.center]);

  const markerClickHandler = () => {
    console.log("position", position);
    dispatch(mapActions.setCenter({center: position}));
  };

  return (
    <div className={`${styles.searchMarkerContainer} ${mapData.isMapDragging && styles.dragging}`} style={{ zIndex: zIndex }}>
      <div className={styles.searchMarker} onClick={markerClickHandler}>
        {(idx + 10).toString(36).toUpperCase()}
      </div>
    </div>
  );
};
export default SearchMarker;
