import React, { useState, useEffect } from "react";
import styles from "./TabMenu.module.css";

const TabMenu = ({ tabList, tabIndex, setTabIndex }) => {
  return (
    <div className={styles.tabContainer}>
      {tabList.map((tabName, index) => {
        return (
          <div className={tabIndex === index ? styles.selected : ""} onClick={() => setTabIndex(index)}>
            {tabName}
          </div>
        );
      })}
    </div>
  );
};
export default TabMenu;
