import React from "react";
import styles from "./BasicInput.module.css";

const BasicInput = ({ isTop, isBottom, children, onClick = () => {} , cursor = "var(--cursor-default)"}) => {
  return (
    <div className={`${styles.input} ${isTop ? styles.top : ""} ${isBottom ? styles.bottom : ""}`} onClick={onClick} style={{cursor: cursor}}>
      {children}
    </div>
  );
};
export default BasicInput;
