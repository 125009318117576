import React from "react";
import styles from "./ArticleLayout.module.css";
import SquareButton from "../SquareButton/SquareButton";

export const ArticleLayout = ({ Title, Content, Controller }) => {
  return (
    <div className={styles.article}>
      <Title />
      <div className={styles.contentContainer}>
        <Content />
      </div>
      <div className={styles.controllerContainer}>
        <Controller />
      </div>
    </div>
  );
};

export const Title = ({ CloseButtonImg, onClose, children }) => {
  return (
    <div className={styles.titleContainer}>
      <div className={styles.title}>{children}</div>
      <div className={styles.closeBtnContainer}>
        <SquareButton onClick={onClose} bdColor="var(--crt-red)">
          <CloseButtonImg stroke="var(--crt-red)"/>
        </SquareButton>
      </div>
    </div>
  );
};

export const Content = ({children}) => {
  return (
    <div className={styles.contentContainer}>
      {children}
    </div>
  );
};

export const Controller = ({children}) => {
  return (
    <div className={styles.controllerContainer}>
      {children}
    </div>
  )
}