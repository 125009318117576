import React from "react";
import { useDispatch } from "react-redux";
import logSlice, { editorActions } from "../../../../store/editor-slice";
import styles from "../Image.module.css";
import cancelImg from "../../../../assets/icon/btn-delete.svg";
import { useState } from "react";

const ImageBox = ({ src, idx, onEditImage }) => {
  const dispatch = useDispatch();

  const onImageCilck = () => {
    console.log("image index changed to ", idx);
    dispatch(editorActions.setImageIndex({ imageIndex: idx }));
  };

  const onDelete = () => {
    dispatch(editorActions.removeImage({ index: idx }));
  };

  return (
    <div className={styles.imageBox}>
      {src ? (
        <div onClick={onImageCilck}>
          <img src={src} alt="" style={{ pointerEvents: "none" }} />
          <div className={styles.cancel} onClick={onDelete}>
            <img src={cancelImg} alt="" />
          </div>
        </div>
      ) : (
        <div className={styles.empty} onMouseDown={(e) => e.stopPropagation()}>
          <label className={styles.button} htmlFor="img-file">
            {/* <img src={cameraImg} alt="" /> */}사진 추가
          </label>
          <input type="file" name="file" id="img-file" multiple onChange={onEditImage} accept=".jpg, .JPG, .png, .PNG, .jpeg, .JPEG" />
        </div>
      )}
    </div>
  );
};
export default ImageBox;
