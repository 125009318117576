import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalData: null,
  warning: "",
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setModal(state, action) {
      state.modalData = action.payload.modalData;
    },
    removeModal(state) {
      state.modalData = null;
    },
    setWarning(state, action) {
      state.warning = action.payload.warning;    
    }
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
