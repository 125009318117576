export default (
  <div style={{fontWeight: 500}}>
    <b>안녕하세요,</b>
    <p>crouton(이하 크루통)에 오신 것을 환영합니다.</p>
    <p>본 약관은 크루통 서비스 이용에 관한 기본적인 사항을 담고 있으므로 주의 깊게 읽어 주시기를 바랍니다.</p>
    <p>
      크루통 서비스는 현재 베타 테스트 단계에 있으며, 실시간으로 문제 분석 및 수정을 진행하고 있습니다. 이로 인해 사이트에 오류나 지연이 발생할 수
      있고, 특정 기능이 없어지거나 변경되고, 새로운 기능이 추가되는 등의 변화가 발생할 수 있습니다. 크루통은 앞으로 더 나은 서비스를 제공하기 위해
      노력하겠습니다.
    </p>
    <br />
    <b>권리 보호</b>
    <p>
      크루통은 서비스 제공을 위해서만 개인 정보를 이용하며, 적법한 절차에 따라 안전하게 관리합니다. 개인정보 보호와 관련된 자세한 내용은 개인정보 처리
      방침에서 확인하실 수 있습니다.
    </p>
    <p>
      크루통 서비스 내 게시물의 저작권은 해당 게시물의 저작자에게 있습니다. 타인의 저작물을 자유롭게 이용하고자 하는 경우에는 ‘저작권법’이 허용하는
      범위 내에서 활용하거나, 해당 게시물의 저작권자에게 별도의 동의를 얻어야 합니다.
    </p>
    <p>
      크루통 사용자의 게시물이 타인의 저작권을 침해하였거나 명예훼손 등의 권리 침해 내용을 담고 있는 경우, 해당 게시물은 게시 중단 또는 삭제될 수
      있습니다. ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’과 ‘저작권법’ 위반에 대한 신고가 접수되면 해당 게시물은 게시 중단하고, 관련 법이 정한
      절차에 따라 추가 조처를 할 수 있습니다.
    </p>
    <br />
    <b>주의사항</b>
    <p>
      모든 사용자의 안전한 서비스 이용을 위해, 아래 같은 경우 크루통 서비스 이용이 제한될 수 있습니다. 주의사항을 준수하여 게시물을 작성하고 서비스를
      이용하시길 바랍니다.
    </p>
    <ul>
      <li>잘못된 방법으로 정상적인 서비스 운영을 방해하는 경우</li>
      <li>영리 및 홍보 목적으로 서비스를 이용하는 경우</li>
      <li>특정 대상을 과도하게 비방하고 음해하는 경우</li>
      <li>타인의 개인 정보를 무단으로 수집, 이용, 공유하는 경우</li>
      <li>타인의 지식재산권 침해, 모욕, 사생활 침해, 권리 침해 등 행위를 수행하는 경우</li>
      <li>성적 수치심을 유발하거나 폭력적이고 잔혹한 내용을 공유해 다른 사용자에게 불쾌감을 일으키는 경우</li>
      <li>크루통 서비스 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보 제공하는 경우</li>
      <li>크루통 서비스 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 크루통 서비스를 복제, 분해, 모방, 변형하는 경우</li>
    </ul>
    <br />
    <b>서비스 이용 제한</b>
    <p>
      크루통은 사용자의 게시물이 본 약관, 관련 법령, 운영 정책에 위배되는 경우, 게시물의 게시를 중단하거나 삭제 처리할 수 있으며 지속적으로 규칙을
      어기는 사용자의 서비스 이용을 제한할 수 있습니다. 다만, 크루통이 모든 게시물을 검토할 의무가 있다는 것은 아닙니다.
    </p>
    <p>
      명백히 법령을 위반하였거나 타인에 대한 권리침해로 피해자가 발생한 경우에는 즉시 이용계약을 해지할 수 있으며, 이 외의 경우에는 단계적으로 서비스
      이용을 제한하겠습니다. 영구적으로 이용이 제한된 사용자는 재가입할 수 없으며, 영구 제한된 사용자의 게시물은 모두 삭제됩니다.
    </p>
    <br />
    <b>서비스 이용 계약 해지</b>
    <p>사용자는 설정 페이지에서 언제든지 크루통 서비스 이용계약 해지 신청을 할 수 있으며, 크루통은 관련 법령에 따라 신속하게 처리하겠습니다.</p>
    <p>
      크루통 서비스 이용 계약이 해지되면, 법령 및 개인정보 처리 방침에 따라 사용자 정보를 보유하는 경우를 제외하고는 사용자의 정보와 게시물을 포함한
      모든 데이터가 삭제됩니다. 다만, 다른 사용자의 게시물에 남긴 댓글이나, 다른 사용자에게 보낸 편지는 삭제되지 않고 남아 있으며, 제삼자에 의해
      공유되거나 저장된 게시물 역시 삭제되지 않습니다.
    </p>
    <br />
    <b>서비스 중단 또는 변경</b>
    <p>
      크루통은 안정적인 서비스 제공을 위해 노력하고 있습니다. 다만, 보수 점검, 고장, 통신 두절 등운영상 어려움이 있는 경우에는 서비스가 일시적으로
      중단될 수 있습니다. 또한, 서비스의 운영 및 개선을 위해 일부 서비스를 수정, 종료할 수 있습니다. 예측 가능한 경우에는 미리 공지할 것이며, 예측
      불가능한 경우라면 상황을 파악하는 즉시 상세히 설명할 것입니다.
    </p>
    <br />
    <b>약관 수정 및 개정</b>
    <p>
      크루통은 서비스와 법률의 변경 사항을 반영하기 위해 약관 및 운영정책을 수정할 수 있습니다. 약관이 수정되는 경우, 변경 사항에 대한 공지사항을
      게시할 것이며 변경된 약관은 고지 7일 후 효력이 발생합니다.
    </p>
    <p>
      크루통은 변경된 약관을 게시한 날로부터 효력이 발생하는 7일 사이 약관 변경에 대한 사용자의 의견을 기다리겠습니다. 해당 기간 동안 약관 변경에 대한
      사용자 의견이 접수되지 않으면, 변경된 약관에 동의하는 것으로 간주됩니다. 사용자가 변경된 약관에 동의하지 않는 경우 변경된 약관의 적용을 받는
      서비스는 제공이 불가능합니다.
    </p>
  </div>
);
