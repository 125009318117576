import React, { useState, useEffect } from "react";
import styles from "./Search.module.css";
import { useNavigate } from "react-router-dom";
import InteractiveBox from "../../../components/InteractiveBox/InteractiveBox";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
import SearchResult from "./Modules/SearchResultModule";
import { ReactComponent as SearchImage } from "../../../assets/icon/btn-search.svg";
import { getPlace } from "../../../axios/gmap";
import { useDispatch, useSelector } from "react-redux";
import { mapActions } from "../../../store/map-slice";
import { editorActions } from "../../../store/editor-slice";

const Search = ({}) => {
  const [keyword, setKeyword] = useState("");
  const [placeList, setPlaceList] = useState([]);
  const navigate = useNavigate();
  const mapData = useSelector((state) => state.map);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mapActions.setWriteModeOn());

    return () => {
      dispatch(mapActions.setWriteModeOff());
    };
  }, []);

  const searchClickHandler = async () => {
    if (keyword === "") {
      return;
    }
    dispatch(editorActions.setPlaceName({ placeName: keyword }));
    const _placeList = await getPlace(keyword);

    const searchMarkerList = [];
    _placeList.map((data, index) => {
      searchMarkerList.push({ index, position: data.geometry.location });
    });
    dispatch(mapActions.setCenterZoom({ lat: searchMarkerList[0].position.lat, lng: searchMarkerList[0].position.lng, zoom: 13 }));
    dispatch(mapActions.setSearchMarkers({ markers: searchMarkerList }));
    setPlaceList(_placeList);
  };

  const nextClickHandler = () => {
    console.log(mapData.center);
    dispatch(editorActions.setLatLng({ lat: mapData.center.lat, lng: mapData.center.lng }));
    dispatch(editorActions.setMarkerStatus());
    navigate("/editor/info", { state: { isProperAccess: true } });
  };
  return (
    <div className="page-divided">
      <div className={`${styles.search} left`}>
        <InteractiveBox size={6}>
          <div className={styles.inputContainer}>
            <TextInput placeholder="SEARCH" type="text" onEnter={searchClickHandler} onChange={setKeyword} value={keyword} />
            <div className={styles.searchButton} onClick={searchClickHandler}>
              <SearchImage stroke="var(--crt-fg-100)" />
            </div>
          </div>
          <div className={styles.searchResult}>
            {placeList.map((data, index) => (
              <SearchResult data={data} key={index} idx={index} />
            ))}
          </div>
        </InteractiveBox>
        <Button isDisabled={false} onClick={nextClickHandler}>
          마커 고정
        </Button>
      </div>
    </div>
  );
};
export default Search;
