import axios from "axios";
import { checkTokenBroken, setLogin, setLogout } from "../utils/auth.js";
import { dataUrIToFile } from "../utils/image.js";

const SERVER = "https://crouton.kr/auth/";
// const SERVER = "http://localhost:8080/auth/";

async function silentRefresh() {
  const isTokenBroken = checkTokenBroken();
  console.log("isTokenBroken", isTokenBroken);
  if (isTokenBroken) {
    const data = (await axios.post(SERVER + "refresh")).data;
    if (data.isSuccess) {
      setLogin(data);
      return true;
    } else {
      setLogout();
      return false;
    }
  } else {
    return true;
  }
}

async function forceRefresh() {
  const data = (await axios.post(SERVER + "refresh")).data;
  if (data.isSuccess) {
    setLogin(data);
    return true;
  } else {
    setLogout();
    return false;
  }
}

async function postLogin(email, password, onSuccess) {
  const data = (await axios.post(SERVER + "login", { email, password })).data;
  if (data.isSuccess) {
    setLogin(data);
    onSuccess();
    return "";
  } else {
    return data.message;
  }
}

async function postJoin(email, authCode, userID, password) {
  console.log("axios posted new join");
  const res = await axios.post(SERVER + "join", {
    email,
    authCode,
    userID,
    password,
  });

  return res.data;
}

async function postProfile(userName, image, isImageDeleted) {
  if (!(await silentRefresh())) return { isSuccess: false, message: "failed" };

  console.log("axios posted new profile");
  const formData = new FormData();

  if (image) {
    const file = dataUrIToFile(image, "profile.jpg");
    formData.append("profile", file);
  }

  formData.append("isImageDeleted", isImageDeleted);

  formData.append("userName", userName);

  const res = await axios({
    method: "POST",
    url: SERVER + "profile",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
}

async function postOverlappingID(userID) {
  console.log("axios got overlap");
  const res = await axios.post(SERVER + "id", {
    userID,
  });
  console.log(res);
  return res.data;
}

async function postEmailAuth(email) {
  console.log("axios posted new Email");
  const res = await axios.post(SERVER + "mail", {
    email,
  });
  console.log(res);
  return res.data;
}

async function postCodeAuth(email, authCode) {
  console.log("axios posted Code to Compare");
  const res = await axios.post(SERVER + "code", {
    email,
    authCode,
  });
  return res.data;
}

async function accessTest() {
  silentRefresh();
}

export { postLogin, postEmailAuth, postJoin, postCodeAuth, postOverlappingID, accessTest, silentRefresh, postProfile, forceRefresh };
