import React, { useState, useEffect } from "react";
import styles from "./Temp.module.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getPlace } from "../../../axios/gmap";
import { useDispatch, useSelector } from "react-redux";
import { mapActions } from "../../../store/map-slice";
import { editorActions } from "../../../store/editor-slice";
import InteractiveBox from "../../../components/InteractiveBox/InteractiveBox";
import Button from "../../../components/Button/Button";
import { Link } from "react-router-dom";

const Temp = ({}) => {
  const navigate = useNavigate();
  const mapData = useSelector((state) => state.map);
  const dispatch = useDispatch();
  
  const nextClickHandler = () => {

  };

  return (
    <div className="page-divided">
      <div className={`${styles.temp} left`}>
        <InteractiveBox size={6}></InteractiveBox>
        <div className={styles.buttonContainer}>
          <Button onClick={nextClickHandler}>이어 쓰기</Button>
          <Link to="/editor/search" className={styles.new}>
            <div>새 로그 기록</div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Temp;
