import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentList: [],
  feedList: [],
  detail: {
    logID: 0,
    title: "",
    imageList: [],
    userID: "",
    userName: "",
    userProfile: "",
    country: "",
    city: "",
    color: "",
    lat: 0,
    lng: 0,
  },
};

const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    setRecentList(state, action) {
      state.recentList = action.payload.recentList;
    },
    setDetail(state, action) {
      state.detail = action.payload.detail;
    },
  },
});

export const logActions = logSlice.actions;

export default logSlice.reducer;
