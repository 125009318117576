import React from "react";
import { useState } from "react";
import styles from './Control.module.css';

const Control = ({image, onClick = () => {}, children, highlighted = false, textOnChange, textChangeCondition = true}) => {

    const [textChanging, setTextChanging] = useState(false);

    const clickHandler = () => {
        if (textOnChange && textChangeCondition) {
            setTextChanging(true);
            setTimeout(() => {
                setTextChanging(false);
            }, 1000);
            onClick();
        } else {
            onClick();
        }
    }


    return (
        <div onClick = {clickHandler} className={`${styles.control} ${highlighted && styles.highlighted} ${textChanging && styles.changing}`}>
            {!textChanging && <div className={styles.imageContainer}>
                { image && <img src={image} alt="" />}
                { children }
            </div>}
            <div className={styles.text}>{textOnChange}</div>
        </div>
    )
}
export default Control;