const getHourMinute = (date = new Date()) => {
  let hourText = "";
  let minuteText = "";
  const hour = date.getHours();
  const minute = date.getMinutes();

  if (hour < 10) {
    hourText = "0" + hour;
  } else {
    hourText = "" + hour;
  }

  if (minute < 10) {
    minuteText = "0" + minute;
  } else {
    minuteText = "" + minute;
  }

  return hourText + ":" + minuteText;
};

export {
  getHourMinute,
};
