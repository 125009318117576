import axios from "axios";
import { dataUrIToFile } from "../utils/image";
import { silentRefresh } from "./auth";
import store from "../store";
import { editorActions } from "../store/editor-slice";

const SERVER = "https://crouton.kr/log/";
// const SERVER = "http://localhost:8080/log/";

const getRecent = async () => {
  console.log("getRecent runs")
  const res = await axios.get(SERVER + "recent");
  return res.data;
};

const getFeed = async () => {
  console.log("getfeed runs")
  const res = await axios.get(SERVER + "feed");
  return res.data;
};

const getLogDetail = async (userID, logID) => {
  console.log("getLogDetail runs -- ")
  const res = await axios.get(`${SERVER}detail/${userID}/${logID}`);
  return res.data;
};

export { getRecent, getFeed, getLogDetail };
