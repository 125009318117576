  const EMAIL_WRONG = "이메일을 형식에 맞게 입력하세요";
  const PASS_SHORT = "8자 이상의 비밀번호를 입력하세요";
  const AUTH_WRONG = "인증번호는 6자리 숫자로만 구성되어 있습니다";
  const AUTH_EXCEEDED = "입력 가능 횟수를 초과했습니다. 인증번호를 재전송하세요";
  const PASS_WRONG = "비밀번호는 8-15자의 숫자/문자/기호로 구성되어야 합니다";
  const PASS_MISMATCH = "비밀번호가 일치하지 않습니다";
  const PP_DISAGREED = "개인정보 처리방침에 동의해주세요";
  const TC_DISAGREED = "서비스 이용약관에 동의해주세요";
  const NAME_WRONG = "사용 불가능한 이름입니다";
  const ID_WRONG = "사용 불가능한 아이디입니다";

export default {
  EMAIL_WRONG,
  PASS_SHORT,
  AUTH_WRONG,
  AUTH_EXCEEDED,
  PASS_WRONG,
  PASS_MISMATCH,
  PP_DISAGREED,
  TC_DISAGREED,
  NAME_WRONG,
  ID_WRONG
}