import React from "react";
import Navigation from "../Navigation/Navigation";
import styles from "./NavList.module.css";
import { ReactComponent as HomeIcon } from "../../assets/icon/nav-home.svg";
import { ReactComponent as SearchIcon } from "../../assets/icon/nav-search.svg";
import { ReactComponent as MapIcon } from "../../assets/icon/nav-map.svg";
import { ReactComponent as WriteIcon } from "../../assets/icon/nav-write.svg";
import searchIcon from "../../assets/icon/nav-search.svg";
import writeIcon from "../../assets/icon/nav-write.svg";
import { useSelector } from "react-redux";
import { genImageUrl, genProfileUrl } from "../../utils/url";
import ProfileImage from "../ProfileImage/ProfileImage";

const NavList = ({ currentPath }) => {
  const userData = useSelector((state) => state.auth);

  return (
    <div className={styles.navList}>
      <Navigation url="/" isActivated={currentPath === "/"} Image={HomeIcon}></Navigation>
      <Navigation url="/search" isActivated={currentPath === "/search"} Image={SearchIcon}></Navigation>
      <Navigation url="/map" isActivated={currentPath === "/map"} Image={MapIcon}></Navigation>

      {userData.isLogin ? (
        <>
          <Navigation url="/editor/search" isActivated={currentPath.startsWith("/editor")} Image={WriteIcon}></Navigation>

          <Navigation url={`/archive/${userData.userID}`} isActivated={currentPath.startsWith("/archive")}>
            <div className={styles.profileContainer}>
              <ProfileImage
                profileUrl={userData.profileImage}
                color={currentPath.startsWith("/archive") ? "var(--crt-fg-300)" : "var(--crt-bg-100)"}
              ></ProfileImage>
            </div>
          </Navigation>
        </>
      ) : (
        <Navigation url="/login" isActivated={currentPath === "/login"}>
          <div className={styles.login}>LOGIN</div>
        </Navigation>
      )}
    </div>
  );
};
export default NavList;
