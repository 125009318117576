import axios from "axios";
import { checkTokenBroken, setLogin, setLogout } from "../utils/auth.js";
import { dataUrIToFile } from "../utils/image.js";

const SERVER = "https://crouton.kr/archive/";
// const SERVER = "http://localhost:8080/archive/";

async function getUserInfo(userID) {
  console.log("axios posted new userInfo", userID);
  const res = await axios.get(SERVER + "user", { params: { userID } });

  return res.data;
}

export { getUserInfo };
