import React, { useState, useEffect, useRef } from "react";
import Button from "../../components/Button/Button";
import styles from "./NewLetter.module.css";
import { useNavigate } from "react-router-dom";

const NewLetter = () => {
  const [letterBody, setLetterBody] = useState("");
  const receiverName = "샹젤리제다니"
  const navigate = useNavigate();
  const cancelClickHandler = () => {
    navigate(-1);
  }
  const sendClickHandler = () => {
    // TODO: send logic
  }
  return (
    <div className={styles.newLetter}>
      <div className={styles.inputContainer}>
        <div className={styles.receiver}>
          {receiverName}
        </div>
        <textarea name="mailBody" id="mailBody" className={styles.mailBody} placeholder="내용을 입력하세요."></textarea>
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={cancelClickHandler}>취소</Button>
        <Button onClick={sendClickHandler} isHighlighted={true} >보내기</Button>
      </div>
    </div>
  );
};
export default NewLetter;
