import React, { useState } from "react";
import styles from "./Slide.module.css";

const Slide = ({ imageList, onEndClick = () => {}, firstCursor = "var(--cursor-prev-d)", lastCursor = "var(--cursor-next-d)" }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const prevImageClickHandler = () => {
    if (slideIndex > 0) {
      setSlideIndex((prev) => prev - 1);
    } else {
      onEndClick();
    }
  };

  const nextImageClickHandler = () => {
    if (slideIndex < imageList.length - 1) {
      setSlideIndex((prev) => prev + 1);
    } else {
      onEndClick();
    }
  };

  return (
    <div className={styles.slideContainer}>
      <div className={styles.slide} style={{ transform: `translateX(${-100 * slideIndex}%)` }}>
        {imageList.map((image, index) => {
          return <img src={image} key={index} />;
        })}
      </div>
      <div className={styles.slideController}>
        <div
          className={`${styles.prev}`}
          onClick={prevImageClickHandler}
          style={{ cursor: slideIndex === 0 ? firstCursor : "var(--cursor-prev)" }}
        ></div>
        <div
          className={`${styles.next}`}
          onClick={nextImageClickHandler}
          style={{ cursor: slideIndex === imageList.length - 1 ? lastCursor : "var(--cursor-next)" }}
        ></div>
      </div>
      <div className={styles.indicatorContainer}>
        {imageList.map((_, index) => {
          return (
            <div
              key={index}
              className={`${styles.indicator} ${slideIndex === index ? styles.isActivated : ""}`}
              onClick={() => {
                setSlideIndex(index);
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};
export default Slide;
