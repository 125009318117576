import React from "react";
import { useDispatch } from "react-redux";
import { mapActions } from "../../../../store/map-slice";
import styles from "../Search.module.css";

const SearchResult = ({ data, idx }) => {
  const dispatch = useDispatch();

  const resultClickHandler = () => {
    dispatch(mapActions.setCenterZoom({lat: data.geometry.location.lat, lng: data.geometry.location.lng, zoom: 15}))
  }
  return (
    <div className={styles.item} onClick={resultClickHandler}>
      <div className={styles.alphabet}>{(idx+10).toString(36).toUpperCase()}</div>
      <div className={styles.place}>
        <div className={styles.placeName}>
          {data.name}
        </div>
        <div className={styles.address}>
          {data.formatted_address}
        </div>
      </div>
    </div> 
  );
};
export default SearchResult;
