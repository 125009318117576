import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { postLogin } from "../../axios/auth";
import warning from "../../utils/warning";

import TextInput from "../../components/TextInput/TextInput";
import Button from "../../components/Button/Button";

import styles from "./Login.module.css";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [warningMsg, setWarningMsg] = useState("");

  const onLoginSuccess = () => {
    navigate("/");
  };

  const loginClickHandler = async (event) => {
    event.preventDefault();
    if (warning.warnEmailWrong(email, warningMsg, setWarningMsg) && warning.warnPassShort(password, warningMsg, setWarningMsg)) {
      const message = await postLogin(email, password, onLoginSuccess);
      setWarningMsg(message);
    }
  };

  const emailInputHandler = (email) => {
    setEmail(email);
    warning.warnEmailWrong(email, warningMsg, setWarningMsg);
  };

  const passwordInputHandler = (password) => {
    setPassword(password);
    warning.warnPassShort(password, warningMsg, setWarningMsg);
  };

  return (
    <div className="page-divided">
      <div className={`${styles.loginContainer} left`}>
        <form action="" className={`${styles.login}`}>
          <TextInput type="email" placeholder="EMAIL" onChange={emailInputHandler} value={email} />
          <TextInput type="password" placeholder="PASSWORD" onChange={passwordInputHandler} value={password} />
          <div className="warning">{warningMsg}</div>
          <Button onClick={loginClickHandler} isDisabled={warningMsg || password.length === 0 || email.length === 0}>
            로그인
          </Button>
          <Link to="/join/terms" className={styles.join}>
            <div>회원가입</div>
          </Link>
          <Link to="/find-password" className={styles.find}>
            <div>비밀번호 찾기</div>
          </Link>
        </form>
      </div>
    </div>
  );
};
export default Login;
