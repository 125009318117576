import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Navigation.module.css";

const Navigation = ({ children, url, isActivated = false, Image = () => <></>, onClick }) => {
  const navigate = useNavigate();

  const navClickHandler = (event) => {
    event.preventDefault();

    if (url) {
      navigate(url);
    } else {
      onClick();
    }
  };
  return (
    <div onClick={navClickHandler} className={`${styles.navigation} ${isActivated ? styles.activated : null}`}>
        <Image stroke={isActivated ? "var(--crt-bg-100)" : "var(--crt-fg-100)"}/>
      {children}
    </div>
  );
};
export default Navigation;
