import React from "react";
import { useDispatch } from "react-redux";
import { editorActions } from "../../../../store/editor-slice";
import styles from "../Info.module.css";

const SearchResult = ({ data, onClick}) => {

  const _onClick = () => {
    onClick(data);
  }

  return (
    <div className={styles.item} onClick={_onClick}>
      <div className={styles.eng}>{data.name_eng}</div>
      <div className={styles.kor}>{data.name_kor}</div>
    </div>
  );
};
export default SearchResult;
