import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { getFeed } from "../../axios/log";

import CardListB from "../../components/CardListB/CardListB";

import { ReactComponent as PeopleImg } from "../../assets/icon/btn-people.svg";
import { ReactComponent as SettingImg } from "../../assets/icon/btn-settings.svg";
import { ReactComponent as FolderImg } from "../../assets/icon/icon-folder.svg";

import Accordion from "../../components/Accordion/Accordion";

import ProfileImage from "../../components/ProfileImage/ProfileImage";

import styles from "./Archive.module.css";
import { useSelector } from "react-redux";
import { archiveAddressParser } from "../../utils/location";
import { getUserInfo } from "../../axios/archive";

const Archive = () => {
  const defaultMenu = { main: "MAP", sub: null };
  const navigate = useNavigate();
  const [isMyArchive, setMyArchive] = useState(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const [isLogShowing, setLogShowing] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [userData, setUserData] = useState({});
  const [activatedMenu, setActivatedMenu] = useState(defaultMenu);

  const myData = useSelector((state) => state.auth);
  const locationData = archiveAddressParser(useLocation().pathname);

  useEffect(() => {
    if (!locationData.userID) {
      // TODO 잘못된 접근입니다.
      return;
    }
    console.log("datas are same", locationData.userID, myData);

    if (locationData.userID === myData.userID) {
      setUserData(myData);
      console.log("datas are same||||||||||||||");
      setMyArchive(true);
    } else {
      getUserArchive(locationData.userID);
    }
  }, [myData]);

  const getUserArchive = async (userID) => {
    const result = await getUserInfo(userID);
    console.log(result);
  };

  const editClickHandler = () => {
    navigate("/profile");
  };
  const logToggleClickHandler = () => {
    setLogShowing(true);
    if (menuOpened) setMenuOpened(false);
  };

  const savedToggleClickHandler = () => {
    setLogShowing(false);
    if (menuOpened) setMenuOpened(false);
  };

  const menuClickHandler = () => {
    setMenuOpened((prev) => !prev);
  };

  return (
    <div className="page-divided">
      <div className={`left ${styles.archive}`}>
        <div className={styles.profileContainer}>
          <div className={styles.profile}>
            <div className={styles.imageContainer}>
              <ProfileImage color="var(--crt-grey)" />
              {isMyArchive && (
                <div className={styles.edit} onClick={editClickHandler}>
                  편집
                </div>
              )}
            </div>
            <div className={styles.userInfo}>
              <div className={styles.userName}>
                {userData.userName}
                {isMyArchive && (
                  <div className={styles.edit} onClick={editClickHandler}>
                    편집
                  </div>
                )}
              </div>
              <div className={styles.userid}>@{userData.userID}</div>
            </div>
          </div>
          <div className={styles.menu} onClick={menuClickHandler}>
            <PeopleImg />
            <div className={styles.peopleIndicator}></div>
            <SettingImg />
          </div>
        </div>
        <Accordion
          data={[
            { title: "MAP", subMenu: [] },
            {
              title: "LOG",
              subMenu: [
                { title: "2016 아시아", icon: FolderImg },
                { title: "2017 일본", icon: FolderImg },
                { title: "2019 아시아", icon: FolderImg },
                { title: "2019 유럽", icon: FolderImg },
                { title: "2022 아시아", icon: FolderImg },
              ],
            },
          ]}
          defaultMenu={defaultMenu}
          activatedMenu={activatedMenu}
          setActivatedMenu={setActivatedMenu}
        />
      </div>
      {activatedMenu.main === "MAP" ? <></> : <div className="right">{activatedMenu.main === "LOG" && <></>}</div>}
    </div>
  );
};
export default Archive;
