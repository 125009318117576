import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  userID: "",
  userNo: 0,
  userName: "",
  tokenExpires: null,
  profileImage: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin(state, action) {
      console.log("name?", action.payload.userName);
      let date = new Date();
      state.isLogin = true;
      state.userID = action.payload.userID;
      state.userNo = action.payload.userNo;
      state.tokenExpires = action.payload.tokenExpires;
      state.userName = action.payload.userName;
      state.profileImage = action.payload.profileImage;
    },
    setLogout(state) {
      state = initialState;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
