import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import TextInput from "../../../components/TextInput/TextInput";
import Button from "../../../components/Button/Button";
import ButtonInput from "../../../components/ButtonInput/ButtonInput";
import InteractiveBox from "../../../components/InteractiveBox/InteractiveBox";

import { postOverlappingID, postCodeAuth, postEmailAuth, postJoin } from "../../../axios/auth";
import warning from "../../../utils/warning";

import styles from "./Info.module.css";
import val from "../../../config/validation";
import { setLogin } from "../../../utils/auth";

const Info = () => {
  const emailBtnTextList = ["인증하기", "인증하기", "재전송"];
  const codeBtnTextList = ["인증하기", "인증하기", "인증완료"];
  const navigate = useNavigate();
  const location = useLocation();

  const [authCompleted, setAuthCompleted] = useState(false);

  const [emailBtnStatus, setEmailBtnStatus] = useState(0);
  const [codeBtnStatus, setCodeBtnStatus] = useState(3);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [warningMsg, setWarningMsg] = useState("");
  const [userIDValid, setUserIDValid] = useState(false);

  useEffect(() => {
    if (!(location.state && location.state.ppStatus + location.state.tcStatus === 4)) {
      navigate("/join/terms", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (warning.warnIDWrong(userID, warningMsg, setWarningMsg)) {
      const checkOverlapping = setTimeout(async () => {
        console.log(userID);
        const result = await postOverlappingID(userID);
        if (result.isSuccess) {
          console.log(result);
          setUserIDValid(true);
        } else {
          setWarningMsg(result.message);
          setUserIDValid(false);
        }
      }, 500);

      return () => {
        clearTimeout(checkOverlapping);
      };
    } else {
      setUserIDValid(false);
    }
  }, [userID]);

  const emailChangeHandler = (value) => {
    warning.warnEmailWrong(value, warningMsg, setWarningMsg) ? setEmailBtnStatus(1) : setEmailBtnStatus(0);
    setEmail(value);
  };

  const emailClickHandler = async () => {
    if (warning.warnEmailWrong(email, warningMsg, setWarningMsg)) {
      setEmailBtnStatus(4);
      const result = await postEmailAuth(email);
      if (result.isSuccess) {
        setEmailBtnStatus(2);
        setCodeBtnStatus(0);
      } else {
        setEmailBtnStatus(1);
        setWarningMsg(result.message);
      }
    }
  };

  const codeChangeHandler = (code) => {
    if (warning.warnAuthWrong(code, warningMsg, setWarningMsg)) {
      setCodeBtnStatus(1);
    } else {
      setCodeBtnStatus(0);
    }
    setCode(val.filterNumber(code, 6));
  };

  const codeClickHandler = async () => {
    if (warning.warnAuthWrong(code, warningMsg, setWarningMsg) && codeBtnStatus === 1) {
      setCodeBtnStatus(4);
      const result = await postCodeAuth(email, code);
      if (result.isSuccess) {
        setEmailBtnStatus(3);
        setCodeBtnStatus(2);
        setAuthCompleted(true);
      } else {
        setWarningMsg(result.message);
      }
    }
  };

  const userIDChangeHandler = (id) => {
    setUserID(id);
  };

  const passwordChangeHandler = (password) => {
    warning.warnPassWrong(password, warningMsg, setWarningMsg);
    setPassword(password);
  };

  const passwordCheckChangeHandler = (text) => {
    warning.warnPassMismatch(password, text, warningMsg, setWarningMsg);
    setPasswordCheck(password);
  };

  const nextClickHandler = async () => {
    if (
      authCompleted &&
      userIDValid &&
      warning.warnIDWrong(userID, warningMsg, setWarningMsg) &&
      warning.warnPassWrong(password, warningMsg, setWarningMsg) &&
      warning.warnPassMismatch(password, passwordCheck, warningMsg, setWarningMsg)
    ) {
      const joinResult = await postJoin(email, code, userID, password);
      if (joinResult.isSuccess) {
        setLogin(joinResult);
        navigate("/join/welcome");
      } else {
        setWarningMsg(joinResult.message);
      }
    }
  };

  return (
    <div className="page-full center">
      <InteractiveBox>
        <ButtonInput textList={emailBtnTextList} status={emailBtnStatus} onClick={emailClickHandler}>
          <TextInput placeholder="EMAIL" type="email" onChange={emailChangeHandler} isDisabled={emailBtnStatus === 3} value={email}/>
        </ButtonInput>
        <ButtonInput textList={codeBtnTextList} status={codeBtnStatus} onClick={codeClickHandler}>
          <TextInput placeholder="CODE" type="phone" onChange={codeChangeHandler} isDisabled={codeBtnStatus >= 2} value={code}/>
        </ButtonInput>
        <div className={styles.idContainer}>
          <TextInput placeholder="ID" onChange={userIDChangeHandler} value={userID}/>
          <div className={`${styles.indicator} ${userID ? styles.visible : ""} ${userIDValid ? styles.valid : ""}`}></div>
        </div>
        <TextInput placeholder="PASSWORD" type="password" onChange={passwordChangeHandler} value={password}/>
        <TextInput placeholder="PASSWORD CHECK" type="password" onChange={passwordCheckChangeHandler} value={passwordCheck} isLast={true}/>
      </InteractiveBox>

      <div className="warning">{warningMsg}</div>
      <Button isDisabled={false} onClick={nextClickHandler}>
        회원가입
      </Button>
    </div>
  );
};
export default Info;
