import React, { useState } from "react";
import ButtonInput from "../../../components/ButtonInput/ButtonInput";
import Button from "../../../components/Button/Button";
import styles from "./Welcome.module.css";
import { useNavigate } from "react-router-dom";
import warning from "../../../utils/warning";
import { Link } from "react-router-dom";
import privacy from "../../../config/privacy";
import { ReactComponent as CroutonIcon } from "../../../assets/icon/crouton-symbol.svg";

const Welcome = () => {
  const navigate = useNavigate();

  const nextClickHandler = () => {
    navigate("/archive/profile");
  }

  return (
    <div className="page-full center">
      <div className={styles.icon}>
        <CroutonIcon fill="var(--crt-fg-100)"></CroutonIcon>
      </div>
      <div className={styles.message}>
        <strong>crouton</strong>에 오신 것을 환영합니다.
        <br />
        여행지에 대한 추억을 기록하고, 그 날의 감상을 나누어보세요.
      </div>
      <Button isDisabled={false} onClick={nextClickHandler}>
        프로필 설정
      </Button>
      <Link to="/" className={styles.home}>
        <div>홈으로 이동</div>
      </Link>
    </div>
  );
};
export default Welcome;
