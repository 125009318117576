export default (
  <div>
    <p>
      크루통 홈페이지 및 모바일 서비스(이하 "크루통"이라 함)가 취급하는 개인정보를 개인정보 보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고
      개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 두고 있습니다.
    </p>
    <p>크루통은 개인정보 처리 방침을 개정하는 경우 웹사이트 공지사항(또는 개별 공지)을 통하여 고지할 것입니다.</p>
    <br />
    <b>[제1조] 개인정보의 처리 항목과 목적 및 보유·이용 기간</b>
    <p>
      크루통은 다음의 목적을 위하여 이용자의 개인정보를 처리하고 있으며, 명시된 목적 이외의 용도로는 처리하지 않습니다. 이용자의 개인정보는 처리
      목적이 달성되면 지체 없이 파기하며, 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행하겠습니다.
    </p>
    <ul>
      <li>
        회원가입 시 수집되는 개인정보 항목
        <ul>
          <li>이메일 주소</li>
          <li>아이디</li>
          <li>비밀번호</li>
        </ul>
      </li>
      <li>
        서비스 이용 과정에서 추가로 수집될 수 있는 개인정보 항목
        <ul>
          <li>IP 주소</li>
          <li>쿠키</li>
          <li>서비스 이용 기록</li>
          <li>기기 정보</li>
          <li>위치 정보</li>
        </ul>
      </li>
      <li>
        개인정보 처리 목적
        <ul>
          <li>서비스 제공</li>
        </ul>
      </li>
      <li>
        개인정보 처리 및 보유 기간
        <ul>
          <li>회원가입 시 수집되는 항목: 회원 탈퇴 시</li>
          <li>서비스 이용 과정에서 추가로 수집될 수 있는 항목: 이용 목적 달성 시</li>
        </ul>
      </li>
    </ul>
    <br />
    <b>[제2조] 개인정보의 파기 절차 및 방법</b>
    <p>
      크루통은 이용자의 개인정보를 원칙적으로 보유·이용 기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때는 지체 없이 해당 개인정보를
      파기합니다.
    </p>
    <p>
      크루통은 전자적 파일형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 기술적인 방법 또는 물리적인 방법을 이용하여 파기하며, 종이에 출력된
      개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여 파기합니다.
    </p>
    <br />
    <b>[제3조] 이용자 및 법정대리인의 권리·의무 및 행사 방법</b>
    <p>이용자 및 법정 대리인은 언제든지 등록된 이용자의 개인정보를 열람(조회)하거나 정정(수정), 삭제를 요청할 수 있습니다.</p>
    <p>
      이용자 및 법정대리인의 개인정보 열람, 정정, 삭제 요청은 담당자에게 서면, 이메일, 모사전송(FAX) 등을 통하여 하실 수 있으며 크루통은 이에 대해
      지체 없이 조치하겠습니다.
    </p>
    <ul>
      <li>담당자: 박다운</li>
      <li>이메일: service@crouton.kr</li>
    </ul>
    <p>개인정보의 열람, 정정, 삭제 등의 요구는 본인 혹은 정당한 대리인인지 확인 절차를 거친 후 열람, 정정, 삭제가 가능합니다.</p>
    <p>이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.</p>
    <p>이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.</p>
    <br />
    <b>[제4조] 개인정보의 안정성 확보 조치</b>
    <p>
      크루통은 이용자의 개인정보를 처리하면서 개인정보가 분실, 도난, 유출, 변조, 훼손 등이 되지 아니하도록 안전성을 확보하기 위하여 다음과 같이
      기술적·관리적 보호 대책을 강구하고 있습니다.
    </p>
    <p>
      크루통은 개인정보 관련 처리 담당자를 최소한으로 제한하며, 개인정보 처리자에 대한 교육 등 관리적 조치를 통해 법령 및 내부 방침 등의 준수를
      강조하고 있습니다.
    </p>
    <p>
      크루통은 개인정보의 보호를 위해 개인정보보호 전담 부서를 운영하고 있으며, 개인정보 처리 방침의 이행사항 및 담당자의 준수 여부를 확인하여 문제가
      발견될 경우 즉시 해결하고 바로잡을 수 있도록 최선을 다하고 있습니다.
    </p>
    <p>크루통은 최신 백신 프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다. </p>
    <p>크루통은 개인정보를 암호화하여 안전하게 저장 및 관리하고 있으며, 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.</p>
    <br />
    <b>[제5조] 개인정보 보호 책임자</b>
    <p>
      크루통은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 고객님의 불만 처리 및 피해구제 등을 위하여 다음과 같이 개인정보
      보호 책임자를 지정하고 있습니다.
    </p>
    <ul>
      <li>성명 : 박다운</li>
      <li>이메일 : service@crouton.kr</li>
    </ul>
    <p>
      이용자는 서비스를 이용하면서 발생한 모든 개인정보와 관련된 문의, 불만 처리, 피해구제 등에 관한 사항을 개인정보 보호 책임자 또는 개인정보보호
      담당 부서로 문의하실 수 있습니다. 크루통은 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다
    </p>
    <br />
    <b>[제6조] 개인정보의 열람청구를 접수·처리하는 부서</b>

    <p>이용자는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.</p>
    <ul>
      <li>부서명 : 기획팀</li>
      <li>담당자 : 박다운</li>
      <li>이메일 : service@ctouron.kr</li>
    </ul>
    <p>이용자의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다</p>

    <br />
    <b>[제7조] 이용자의 권익침해에 대한 구제 방법</b>
    <p>
      이용자는 다음의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 다음의 기관은 정부 기관 소속으로서, 크루통의 자체적인
      개인정보 불만 처리 또는 개인정보 피해구제 결과에 만족하지 못하실 경우, 자세한 도움이 필요하실 경우에는 문의하여 주시기를 바랍니다.
    </p>
    <ul>
      <li>
        개인정보침해신고센터
        <ul>
          <li>
            홈페이지 : <a href="http://privacy.kisa.or.kr/kor/main.jsp">http://privacy.kisa.or.kr/kor/main.jsp</a>
          </li>
          <li>전화번호 : (국번없이) 118</li>
        </ul>
      </li>
      <li>
        개인정보분쟁조정위원회
        <ul>
          <li>
            홈페이지 : <a href="https://www.kopico.go.kr/">https://www.kopico.go.kr/</a>
          </li>
          <li>전화번호 : 1833-6972</li>
        </ul>
      </li>
      <li>
        대검찰청 사이버수사과
        <ul>
          <li>
            홈페이지 : <a href="http://www.spo.go.kr/spo/index.jsp"> http://www.spo.go.kr/spo/index.jsp </a>
          </li>
          <li>전화번호 : (국번없이) 1301</li>
        </ul>
      </li>
      <li>
        경찰청 사이버안전국
        <ul>
          <li>
            홈페이지 : <a href="https://ecrm.police.go.kr/minwon/main">https://ecrm.police.go.kr/minwon/main</a>
          </li>
          <li>전화번호 : (국번없이) 182</li>
        </ul>
      </li>
    </ul>

    <br />
    <b>[제8조] 개인정보 처리 방침의 변경</b>

    <p>
      현 개인정보 처리 방침은 법령, 정부의 정책 또는 크루통 내부 정책 등 필요에 의하여 변경될 수 있으며, 내용 추가, 삭제 및 수정이 있을 시에는 개정
      최소 7일 전부터 홈페이지의 고객센터를 통해 고지할 것입니다. 다만, 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
    </p>
    <p>현 개인정보 처리 방침은 2023년 2월 28일부터 적용됩니다</p>
    <p>
      공고 일자: 2023년 1월 28일
      <br />
      시행 일자: 2023년 2월 28일
    </p>
    <br />
  </div>
);
