import React, { useState, useEffect } from "react";
import ColorDot from "../../../../components/ColorDot/ColorDot";
import styles from "../Info.module.css";
import { useDispatch, useSelector } from "react-redux";
import BasicInput from "../../../../components/BasicInput/BasicInput";
import { editorActions } from "../../../../store/editor-slice";
import { getRandomColor } from "../../../../utils/map";
import { mapActions } from "../../../../store/map-slice";

const ColorModule = () => {
  const editorData = useSelector((state) => state.editor);
  const dispatch = useDispatch();

  const colorTemplate = ["#ED553B", "#F6D55C", "#3CAEA3", "#20639B"];
  const colorIndex = editorData.colorIndex;

  useEffect(() => {
    if (colorIndex !== 4) {
      dispatch(mapActions.setLockedMarkerColor({ color: colorTemplate[colorIndex] }));
      dispatch(editorActions.setColor({ color: colorTemplate[colorIndex] }));
    }
  }, [colorIndex]);

  const randomColorClickHandler = () => {
    dispatch(editorActions.setColorIndex({ colorIndex: 4 }));
    const randomColor = getRandomColor();
    dispatch(editorActions.setColor({ color: randomColor }));
  };

  return (
    <BasicInput>
      <div className={styles.title}>MARKER COLOR</div>
      <div className={styles.palette}>
        <ColorDot color={"#ED553B"} dotID={0} />
        <ColorDot color={"#F6D55C"} dotID={1} />
        <ColorDot color={"#3CAEA3"} dotID={2} />
        <ColorDot color={"#20639B"} dotID={3} />
        <ColorDot dotID={4} showImage={true} onClick={randomColorClickHandler} />
      </div>
    </BasicInput>
  );
};

export default ColorModule;
