import React from "react";
import styles from "../Map.module.css";
import { useSelector } from "react-redux";
import {ReactComponent as MarkerImg} from "../../../assets/icon/marker-center.svg";

const WriteMarker = ({ isVisible, fixed }) => {     
  
  return (
    <div className={`${styles.writeMarker} ${isVisible ? styles.visible : ""} ${fixed ? styles.fixed : ""}`}>
      <MarkerImg />
    </div>
  );
};
export default WriteMarker;
