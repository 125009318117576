import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { uiActions } from "../../store/ui-slice";

import styles from "./Modal.module.css";
import Button from "../Button/Button";


const ReportModal = () => {
  const [reportMsg, setReportMsg] = useState("");
  const [warning, setWarning] = useState("");

  const dispatch = useDispatch();

  const submitClickHandler = () => {
    //TODO SERVER COMM
    if(reportMsg === ""){
      
    } else {
      console.log(reportMsg);
      dispatch(uiActions.removeModal())
    }
  };

  const reportMsgChangeHandler = (e) => {
    setReportMsg(e.target.value);
  };
  return (
    <>
      <input
        type="text"
        name="report"
        id="report"
        className={styles.reportModal}
        placeholder="신고 사유를 입력해주세요"
        onChange={reportMsgChangeHandler}
      />
      <div className={styles.warning}>{warning}</div>
      <Button onClick={submitClickHandler}>신고하기</Button>
    </>
  );
};
export default ReportModal;
