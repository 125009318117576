import React, { useState, useEffect } from "react";
import styles from "./Search.module.css";
import Tray from "../../components/Navigation/Navigation";
import { ReactComponent as SearchImg } from "../../assets/icon/btn-search.svg";
import GridView from "./module/GridView";
import TabMenu from "../../components/TabMenu/TabMenu";
import { getRecent } from "../../axios/log";
import { useDispatch, useSelector } from "react-redux";
import { logActions } from "../../store/log-slice";
import { mapActions } from "../../store/map-slice";

const Search = () => {
  const dispatch = useDispatch();
  const logData = useSelector((state) => state.log);
  const [tabIndex, setTabIndex] = useState(0);

  const getRecentLogList = async () => {
    const recentLogList = (await getRecent()).logList;
    const markerList = [];
    recentLogList.map((logData, idx) => {
      markerList.push({
        position: {
          lat: logData.lat,
          lng: logData.lng,
        },
        color: logData.color,
      });
    });
    dispatch(mapActions.setLogMarkers({ logMarkers: markerList }));
    dispatch(logActions.setRecentList({ recentList: recentLogList }));
  };

  useEffect(() => {
    if (!logData.recentList || logData.recentList.length === 0) {
      getRecentLogList();
    }
  }, []);

  return (
    <div className="page-divided">
      <div className="left">
        <div className={styles.searchBoxContainer}>
          <input type="text" name="searchBox" id="searchBox" className={styles.searchBox} placeholder={"#파리"} />
          <div className={styles.searchButton}>
            <SearchImg stroke="var(--crt-fg-100)" />
          </div>
        </div>
        <TabMenu setTabIndex={setTabIndex} tabIndex={tabIndex} tabList={["RECENT", "TOP"]}></TabMenu>
        <div className={styles.searchResult}>
          <GridView logList={logData.recentList}></GridView>
        </div>
      </div>
    </div>
  );
};
export default Search;
