import React from "react";
import { Link } from "react-router-dom";
import styles from "./Accordion.module.css";
import { ReactComponent as Arrow } from "../../assets/icon/arrow-bottom.svg";

const Accordion = ({ data, activatedMenu, setActivatedMenu, defaultMenu }) => {
  console.log("final activated menu", activatedMenu);
  return (
    <div className={styles.accordionContainer}>
      <div className={styles.accordion}>
        {data.map((menu, index) => {
          const isMainActivated = activatedMenu.main === menu.title;
          return (
            <div
              key={index}
              className={styles.menu}
              style={{ height: isMainActivated ? (menu.subMenu.length + 1) * 60 + menu.subMenu.length * 2 : 60 }}
            >
              <div
                className={`${styles.menuTitle} ${isMainActivated ? styles.activated : ""}`}
                onClick={() => {
                  setActivatedMenu({ main: menu.title });
                }}
              >
                {menu.title}
                <Arrow
                  className={`${menu.subMenu.length === 0 ? styles.horizontal : styles.vertical}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (isMainActivated) setActivatedMenu(defaultMenu);
                    else setActivatedMenu({ main: menu.title });
                  }}
                />
              </div>
              <div className={styles.subMenuContainer}>
                {menu.subMenu &&
                  menu.subMenu.map((subMenu, idx) => {
                    return (
                      <div
                        className={`${styles.subMenu} ${subMenu.title === activatedMenu.sub ? styles.activated : ""}`}
                        key={idx}
                        onClick={() => {
                          setActivatedMenu({ main: menu.title, sub: subMenu.title });
                          console.log(activatedMenu);
                        }}
                      >
                        <subMenu.icon stroke="var(--crt-fg-100)" className={styles.icon} />
                        <div className={styles.subTitle}>{subMenu.title}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Accordion;
