import React from "react";
import styles from "./Button.module.css";

const Button = ({ type = "submit", children, onClick, isDisabled = false }) => {
  return (
    <button type={type} onClick={onClick} className={`${styles.button} ${isDisabled ? styles.disabled : ""}`}>
      {children}
    </button>
  );
};
export default Button;
