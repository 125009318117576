import React from "react";
import { Link } from "react-router-dom";
import styles from "./Letter.module.css";

const Letter = ({ data }) => {


  return (
    <Link to={data.letterUrl}>
      <div className={styles.letter}>
        <div className={styles.info}>
          <div className={styles.sender}>
            <div className={styles.name}>
              {data.senderName}
            </div>
            <div className={styles.id}>
              @{data.senderID}
            </div>
            <div className={styles.notification}></div>
          </div>
          <div className={styles.date}>
            {data.date}
          </div>
        </div>
        <div className={styles.text}>
          {data.body}
        </div>
      </div>
    </Link>
  );
};
export default Letter;
