import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  centerMarkerVisible: false,
  writeMarker: {
    lat: 0,
    lng: 0,
    isVisible: false,
  },
  logMarkers: [],
  searchResultMarkers: [],
  center: { lat: 37.5642135, lng: 127.0016985 },
  zoom: 12,
  isMapDragging: false,
  isLocked: false,
  lockedMarkerColor: "#162B84",
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setWriteModeOn(state) {
      state.centerMarkerVisible = true;
      state.writeMarker.isVisible = false;
      state.editorMarkers = [];
      state.searchResultMarkers = [];
    },
    setWriteModeOff(state) {
      state.centerMarkerVisible = false;
      state.writeMarker.isVisible = true;
      state.editorMarkers = [];
      state.searchResultMarkers = [];
    },
    setSearchMarkers(state, action) {
      state.searchResultMarkers = action.payload.markers;
    },
    setWriteMarker(state, action) {
      state.writeMarker = { lat: action.payload.lat, lng: action.payload.lng, isVisible: true };
    },
    setCenter(state, action) {
      state.center = action.payload.center;
    },
    setZoom(state, action) {
      state.zoom = action.payload.zoom;
    },
    setCenterZoom(state, action) {
      state.center = { lat: action.payload.lat, lng: action.payload.lng };
      state.zoom = action.payload.zoom;
    },
    startMapDragging(state) {
      state.isMapDragging = true;
    },
    endMapDragging(state) {
      state.isMapDragging = false;
    },
    lockMap(state) {
      state.isLocked = true;
    },
    unLockMap(state) {
      state.isLocked = false;
    },
    setLockedMarkerColor(state, action) {
      state.lockedMarkerColor = action.payload.color;
    },
    setLogMarkers(state, action) {
      state.logMarkers = action.payload.logMarkers;
    },
  },
});

export const mapActions = mapSlice.actions;

export default mapSlice.reducer;
