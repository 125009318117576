import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth-slice";
import editorSlice from "./editor-slice";
import logSlice from "./log-slice";
import mapSlice from "./map-slice";
import uiSlice from "./ui-slice";

const store = configureStore({
  reducer: {
    ui: uiSlice,
    auth: authSlice,
    map: mapSlice,
    editor: editorSlice,
    log: logSlice,
  },
  devTools: true,
});

export default store;
