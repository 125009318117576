import React from "react";
import styles from "./InteractiveBox.module.css";

const InteractiveBox = ({ size = 5, children}) => {
  return (
    <div className={styles.interactiveBox} style={{height: 60 * size + 10}}>
      {children}
    </div>
  );
};
export default InteractiveBox;
