import styles from "./ButtonInput.module.css";
import { ReactComponent as CroutonIcon } from "../../assets/icon/crouton-symbol.svg";

const ButtonInput = ({ children, textList, status, onClick }) => {
  const _onClick = (event) => {
    event.preventDefault();
    onClick();
  };
  return (
    <div className={`${styles.input}`}>
      {children}
      <button
        onClick={_onClick}
        className={`
          ${status === 1 ? styles.highlighted : ""} 
          ${status === 2 ? styles.finished : ""} 
          ${status === 3 ? styles.hidden : ""} 
          ${status === 4 ? styles.loading : ""}
        `}
      >
        {status < 3 ? (
          textList[status]
        ) : (
          <div className={styles.iconContainer}>
            <CroutonIcon fill="var(--crt-bg-100)" />
          </div>
        )}
      </button>
    </div>
  );
};
export default ButtonInput;
