import React, { useState, useEffect } from "react";
import styles from "../Search.module.css";
import GridItem from "./GridItem";

const GridView = ({ logList }) => {
  console.log("logList: ", logList);
  return (
    <div className={styles.gridView}>
      {logList.map((logData, index) => {
        return <GridItem logData={logData} key={index} />;
      })}
    </div>
  );
};
export default GridView;
