const checkEmail = (data) => /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(data);
const checkPassword = (data) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,15}$/.test(data);
const checkpassLength = (data) => data && data.length > 7;
const checkNumber = (data) => /^[0-9]+$/.test(data);
const checkAuth = (data) => data.length === 6 && checkNumber(data);
const checkID = (data) => /^[a-zA-Z_](?!.*?\.{2})[\w.]{1,20}[\w]$/.test(data);
const checkPassMatch = (target, data) => target === data;
const checkAgreed = (data) => data === 2;
const checkUserName = (data) => /^[가-힣a-zA-Z0-9]{2,10}$/.test(data);

const filterNumber = (data, length) => data.replace(/[^0-9]/g, "").slice(0, length);

const filterMonth = (data) => {
  const number = parseInt(data);
  console.log(number)
  if (number > 12) {
    return "12";
  } else if (number < 1) {
    return "1";
  } else {
    return data;
  }
};
export default {
  checkEmail,
  checkPassword,
  checkpassLength,
  checkNumber,
  checkAuth,
  checkUserName,
  checkID,
  checkPassMatch,
  checkAgreed,
  filterNumber,
  filterMonth,
};
