import React, { useState, useEffect, useCallback } from "react";
import styles from "./Image.module.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
// import searchImg from "../../../assets/search.svg";
import AddrSearchResult from "../Search/Modules/SearchResultModule";
import { getPlace } from "../../../axios/gmap";
import { useDispatch, useSelector } from "react-redux";
import { mapActions } from "../../../store/map-slice";
// import cameraImg from "../../../assets/camera.svg";
import ColorDot from "../../../components/ColorDot/ColorDot";
import ImageBox from "./Modules/ImageBox";
import { getCroppedImg, readFile } from "../../../utils/image";
import { editorActions } from "../../../store/editor-slice";
import imageCompression from "browser-image-compression";
import { postImages } from "../../../axios/editor";
import Cropper from "react-easy-crop";
import { ReactComponent as ArrowImg } from "../../../assets/icon/arrow-left.svg";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { GridContextProvider, GridDropZone, GridItem, swap, move } from "react-grid-dnd";

const Image = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const editorData = useSelector((state) => state.editor);
  const imgIdx = editorData.imageIndex;
  const [isDraggable, setDraggable] = useState(true);

  // const [tempImage, setTempImage] = useState([]);
  const [items, setItems] = useState([1, 2, 3, 4]); // supply your own state

  // target id will only be set if dragging from one dropzone to another.
  function _onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
  }

  const dispatch = useDispatch();
  // const image = editorData.images[imgIdx] || {
  //   croppedAreaPixels: null,
  //   crop: { x: 0, y: 0 },
  //   zoom: 1,
  // };

  const imageCropHandler = (crop) => {
    dispatch(editorActions.cropImage({ imageIndex: imgIdx, crop: crop }));
  };

  const imageZoomHandler = (zoom) => {
    dispatch(editorActions.zoomImage({ imageIndex: imgIdx, zoom: zoom }));
  };

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(editorData.images[editorData.imageIndex].original, croppedAreaPixels);
    dispatch(editorActions.setCroppedImage({ imageIndex: imgIdx, image: croppedImage }));
    dispatch(editorActions.setCroppedPixels({ imageIndex: imgIdx, croppedAreaPixels: croppedAreaPixels }));
  };

  const leftClickHandler = () => {
    const imgIdx = editorData.imageIndex;
    if (imgIdx > 0) {
      dispatch(editorActions.setImageIndex({ imageIndex: imgIdx - 1 }));
    }
  };

  const rightClickHandler = () => {
    const imgIdx = editorData.imageIndex;
    if (imgIdx < editorData.images.length - 1) {
      dispatch(editorActions.setImageIndex({ imageIndex: imgIdx + 1 }));
    }
  };

  const changeCroppedImage = async () => {};

  const compressOptions = {
    maxSizeMB: 5,
    maxWidthOrHeight: 2000,
  };

  const EditImageChangeHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let files = [];
      for (let index = 0; index < event.target.files.length; index++) {
        if (editorData.images.length <= 10) {
          const compressedFile = await imageCompression(event.target.files[index], compressOptions);
          let imageDataUrI = await readFile(compressedFile);
          files.push(imageDataUrI);

          dispatch(editorActions.addImage({ image: imageDataUrI }));
          dispatch(editorActions.setImageIndex({ imageIndex: index }));
        }
      }
    }
  };

  const completeClickHandler = async () => {
    if (await postImages()) navigate("/editor/article");
  };

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const result = swap(editorData.images, sourceIndex, targetIndex);
    return dispatch(editorActions.reorderImage({ reorderedImage: result }));
  }

  return (
    <div className="page-divided">
      <div className="left">
        <div className={styles.leftContainer}>
          <GridContextProvider onChange={onChange}>
            <div className={styles.innerContainer}>
              <GridDropZone className={styles.imageBoxContainer} id="images" boxesPerRow={2} rowHeight={320} draggable={false}>
                {editorData.images.map((image, idx) => (
                  <GridItem key={"name" + image.index}>
                    <div className={styles.imageGridItem}>
                      <ImageBox src={image.cropped} idx={idx} onEditImage={EditImageChangeHandler} />
                    </div>
                  </GridItem>
                ))}
                <GridItem>{editorData.images.length < 10 ? <ImageBox onEditImage={EditImageChangeHandler} /> : null}</GridItem>
              </GridDropZone>
            </div>
          </GridContextProvider>
          <div className={styles.buttonContainer}>
            <Button isHighlighted={true} onClick={completeClickHandler}>
              완료
            </Button>
          </div>
        </div>
      </div>

      <div className="right">
        {editorData.images.length === 0 ? null : (
          <div className={styles.cropperContainer}>
            <div className={styles.controller}>
              <div className={styles.prev} onClick={leftClickHandler}>
                <ArrowImg stroke={editorData.imageIndex === 0 ? "var(--crt-grey)" : "var(--crt-fg-100)"} />
                <div className={`${editorData.imageIndex === 0 ? styles.disactivated : ""}`}>이전</div>
              </div>
              <div className={styles.pagenation} onClick={changeCroppedImage}>
                {editorData.imageIndex + 1}/{editorData.images.length}
              </div>
              <div className={styles.next} onClick={rightClickHandler}>
                <div className={`${editorData.imageIndex === editorData.images.length - 1 ? styles.disactivated : ""}`}>다음</div>
                <ArrowImg stroke={editorData.imageIndex === editorData.images.length - 1 ? "var(--crt-grey)" : "var(--crt-fg-100)"} style={{ rotate: "180deg" }} />
              </div>
            </div>
            <div className={styles.cropper}>
              <Cropper
                image={editorData.images[editorData.imageIndex].original}
                crop={editorData.images[editorData.imageIndex].crop}
                zoom={editorData.images[editorData.imageIndex].zoom}
                aspect={1}
                onCropChange={imageCropHandler}
                onZoomChange={imageZoomHandler}
                onCropComplete={onCropComplete}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Image;
