import React, { useState, useEffect } from "react";
import styles from "./Article.module.css";
import { useNavigate } from "react-router-dom";
import Slide from "../../../components/Slide/Slide";
import Button from "../../../components/Button/Button";
import { getHourMinute } from "../../../utils/date";
import { postPublish, putArticle, putTitle } from "../../../axios/editor";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "../../../store/editor-slice";
import { Controller, Content, Title } from "../../../components/ArticleLayout/ArticleLayout";
import SquareButton from "../../../components/SquareButton/SquareButton";
import { ReactComponent as LockImg } from "../../../assets/icon/sq-lock.svg";
import { ReactComponent as UnlockImg } from "../../../assets/icon/sq-unlock.svg";
import { ReactComponent as DeleteImg } from "../../../assets/icon/sq-delete.svg";
import { ReactComponent as FolderImg } from "../../../assets/icon/icon-folder-tr.svg";
import { ReactComponent as LocationImg } from "../../../assets/icon/icon-location-tr.svg";

const Article = ({}) => {
  const navigate = useNavigate();
  const editorData = useSelector((state) => state.editor);

  const locationClickHandler = () => {
    navigate("/editor/info");
  };

  const imageClickHandler = () => {
    navigate("/editor/image");
  };

  const imageList = editorData.images.map((value) => value.cropped);

  useEffect(() => {
    if (!editorData.status.isMarkerSet) {
      // navigate("/editor/search", { replace: true });
    }
    if (!editorData.status.isLocationSet) {
      // navigate("/editor/info", { replace: true });
    }

    // window.addEventListener("beforeunload", (event) => {
    //   event.preventDefault();
    //   event.returnValue = "";
    // });
  }, []);

  const dispatch = useDispatch();
  const [now, setNow] = useState("");

  const [isTempSaved, setTempSaved] = useState(true);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (!isTempSaved) {
        const result = await putArticle(editorData.article, editorData.logID);
        console.log(result);
        setTempSaved(result);
        setNow(getHourMinute());
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [editorData.article]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (!isTempSaved) {
        const result = await putTitle(editorData.title, editorData.logID);
        setTempSaved(result);
        setNow(getHourMinute());
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [editorData.title]);

  const contentChangeHandler = (event) => {
    dispatch(editorActions.setArticle({ article: event.target.value }));
    setTempSaved(false);
  };

  const titleChangeHandler = (event) => {
    dispatch(editorActions.setTitle({ title: event.target.value }));
    setTempSaved(false);
  };

  const privacyClickHandler = () => {
    dispatch(editorActions.flipPrivacy());
  };

  const completeClickHandler = async () => {
    console.log(isTempSaved);
    if (isTempSaved) {
      if (await postPublish(editorData.logID)) {
        navigate("/");
        dispatch(editorActions.resetAll());
      }
    }
  };

  return (
    <div className="page-divided">
      <div className="left">
        <div className={styles.leftContainer}>
          <div className={styles.imageContainer}>
            {editorData.images.length === 0 ? (
              <div className={styles.empty} onClick={imageClickHandler}>
                <div>사진 추가</div>
              </div>
            ) : (
              <Slide
                imageList={imageList}
                onEndClick={() => navigate("/editor/image")}
                firstCursor={"var(--cursor-add)"}
                lastCursor={"var(--cursor-add)"}
              />
            )}
          </div>
          <div className={styles.infoContainer} onClick={locationClickHandler}>
            <div className={styles.info}>
              <div className={styles.folder}>
                <FolderImg stroke="var(--crt-fg-100)"/>
                <div>기본 폴더</div>
              </div>
              <div className={styles.placeName}>
                <LocationImg stroke="var(--crt-fg-100)"/>
                <div>{editorData.placeName}</div>
              </div>
            </div>
            <div className={styles.location}>
              <div className={styles.country}>{editorData.country.name_eng}</div>
              <div className={styles.cityContainer}>
                <div className={styles.colorDot} style={{ backgroundColor: editorData.color }}></div>
                <div className={styles.city}>{editorData.city.name_eng}</div>
              </div>

              <div className={styles.date}>
                {editorData.year}. {editorData.month}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <Title CloseButtonImg={DeleteImg}>
          <input type="text" className={styles.title} placeholder="TITLE" onChange={titleChangeHandler} value={editorData.title} />
        </Title>
        <Content>
          <textarea className={styles.article} placeholder="CONTENT" onChange={contentChangeHandler} value={editorData.article}></textarea>
        </Content>
        <Controller>
          <div className={`${styles.temp} ${isTempSaved ? styles.visible : ""}`}>자동저장 완료 {now}</div>
          <div className={styles.controls}>
            <SquareButton onClick={privacyClickHandler} textOnChange={editorData.isPrivate ? "PRIVATE" : "PUBLIC"}>
              {editorData.isPrivate ? <LockImg stroke="var(--crt-fg-100)" /> : <UnlockImg stroke="var(--crt-fg-100)" />}
            </SquareButton>
            <SquareButton
              btnText="업로드"
              bgColor="var(--crt-blue)"
              color="var(--crt-bg-100)"
              bdColor="var(--crt-blue)"
              onClick={completeClickHandler}
            />
          </div>
        </Controller>
      </div>
    </div>
  );
};
export default Article;
