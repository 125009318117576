import React from "react";

import { ReactComponent as DefaultProfileImage } from "../../assets/icon/icon-user.svg";

const ProfileImage = ({ profileUrl, color }) => {
  return profileUrl ? (
    <img src={`https://crouton-img.s3.ap-northeast-2.amazonaws.com/${[profileUrl]}`} alt="" />
  ) : (
    <DefaultProfileImage fill={color} />
  );
};
export default ProfileImage;
