import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LetterDetail.module.css";
import Control from "../../components/Control/Control";

import deleteImage from "../../assets/icon/sq-delete.svg";
import cancelImage from "../../assets/icon/sq-close.svg";
import reportImage from "../../assets/icon/sq-report.svg";
import ReportModal from "../../components/Modals/ReportModal";
import DeleteModal from "../../components/Modals/DeleteModal";

const LetterDetail = ({ setModalData }) => {
  const [letterData, setLetterData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      //TODO GET DATA FROM SERVER
      const tempLetterData = {
        letterUrl: "/letter/UUID",
        senderName: "샹젤리제다니",
        senderID: "sun-w-danni",
        receiverID: "my-id",
        receiverName: "my-name",
        date: "2022. 11. 27 19:00",
        body: "안녕하세요, 헤이즈님. 노래 듣고 편지 보냅니다. 노래가 참 좋네요. 즐거운 하루 되세요. 라일레이로 떠나는 날, 저희는 가방을 숙소에 맡기고안녕하세요, 헤이즈님. 노래 듣고 편지 보냅니다. 노래가 참 좋네요. 즐거운 하루 되세요. 라일레이로 떠나는 날, 저희는 가방을 숙소에 맡기고안녕하세요, 헤이즈님. 노래 듣고 편지 보냅니다. 노래가 참 좋네요. 즐거운 하루 되세요. 라일레이로 떠나는 날, 저희는 가방을 숙소에 맡기고안녕하세요, 헤이즈님. 노래 듣고 편지 보냅니다. 노래가 참 좋네요. 즐거운 하루 ",
      };
      setLetterData(tempLetterData);
    })();
  }, []);

  const deleteClickHandler = () => {
    setModalData(<DeleteModal moveTo="/letter-box" setModalData={setModalData} />)
  }

  const reportClickHandler= () => {
    setModalData(<ReportModal setModalData={setModalData}/>)
  };
  const replyClickHandler = () => {
    navigate("/letter/new");
  };

  const cancelClickHandler = () => {
    navigate(-1);
  };

  const senderInfo = letterData && letterData.senderID !== "my-id" && (
    <div className={styles.senderInfo}>
      <div className={styles.name}>{letterData.senderName}</div>
      <div className={styles.id}>@{letterData.senderID}</div>
    </div>
  );

  const receiverInfo = letterData && letterData.receiverID !== "my-id" && (
    <div className={styles.receiverInfo}>
      <div className={styles.name}>{letterData.senderName}</div>
      <div className={styles.id}>@{letterData.senderID}</div>
    </div>
  );

  return (
    <div className={styles.letterDetail}>
      <div className={styles.letter}>
        {senderInfo}
        <div className={styles.body}>{letterData && letterData.body}</div>
        {receiverInfo}
      </div>

      <div className={`${styles.controller} ${styles.left}`}>
        <Control image={deleteImage} onClick={deleteClickHandler}></Control>

        <Control image={reportImage} onClick={reportClickHandler}></Control>
      </div>

      <div className={`${styles.controller} ${styles.right}`}>
        {/* <Control image={letterImage} onClick={replyClickHandler} /> */}
        <Control image={cancelImage} onClick={cancelClickHandler} />
      </div>
    </div>
  );
};
export default LetterDetail;
