import React from "react";
import Letter from "../Letter/Letter";
import styles from './LetterList.module.css';

const LetterList = ({letterDataList = []}) => {

    return (
        <div className={styles.letterListContainer}>
            <div className={styles.letterList}>
                {letterDataList.map((data, index) => 
                    <Letter data={data} key={index}/>
                )}
            </div>
        </div>
    )
}
export default LetterList;