import val from "../config/validation";
import code from "../config/error-code";

const checkWarning = (condition, prevWarning, newWarning, setMsg) => {
  if (prevWarning === newWarning && condition) {
    setMsg("");
  } else if (!condition) {
    setMsg(newWarning);
  }

  return condition;
};

const warnEmailWrong = (email, prev, setMsg) => checkWarning(val.checkEmail(email), prev, code.EMAIL_WRONG, setMsg);
const warnPassShort = (pass, prev, setMsg) => checkWarning(val.checkpassLength(pass), prev, code.PASS_SHORT, setMsg);
const warnAuthWrong = (auth, prev, setMsg) => checkWarning(val.checkAuth(auth), prev, code.AUTH_WRONG, setMsg);
const warnPassWrong = (pass, prev, setMsg) => checkWarning(val.checkPassword(pass), prev, code.PASS_WRONG, setMsg);
const warnPassMismatch = (pass, target, prev, setMsg) => checkWarning(val.checkPassMatch(pass, target), prev, code.PASS_MISMATCH, setMsg);
const warnPPDisagreed = (status, prev, setMsg) => checkWarning(val.checkAgreed(status), prev, code.PP_DISAGREED, setMsg);
const warnTCDisagreed = (status, prev, setMsg) => checkWarning(val.checkAgreed(status), prev, code.TC_DISAGREED, setMsg);
const warnNameWrong = (name, prev, setMsg) => checkWarning(val.checkUserName(name), prev, code.NAME_WRONG, setMsg);
const warnIDWrong = (id, prev, setMsg) => checkWarning(val.checkID(id), prev, code.ID_WRONG, setMsg);

export default {
  warnEmailWrong,
  warnPassShort,
  warnAuthWrong,
  warnPassWrong,
  warnPassMismatch,
  warnPPDisagreed,
  warnTCDisagreed,
  warnNameWrong,
  warnIDWrong,
};
