import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./ListView.module.css";
import { genImageUrl } from "../../utils/url";
import { mapActions } from "../../store/map-slice";

const ListItem = ({ logData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageCount = 3;

  const LogClickHandler = () => {
    navigate(`/log/${logData.userID}/${logData.logID}`);
  };

  const locationClickHandler = (event) => {
    event.stopPropagation();
    dispatch(mapActions.setCenter({ center: { lat: logData.lat, lng: logData.lng } }));
  };

  return (
    <div className={styles.listItem} onClick={LogClickHandler}>
      <div className={styles.imageContainer}>
        <img src={genImageUrl(logData.thumb)} alt="" />
        {imageCount > 0 && <div className={styles.imageCount}>+{imageCount - 1}</div>}
        <div className={styles.locationContainer} onClick={locationClickHandler}>
          <div className={styles.marker} style={{ backgroundColor: logData.color }}></div>
          <div className={styles.location}>
            {logData.city}, {logData.country}
          </div>
          {/* <div className={`${styles.location} marquee`}>
            <div className="outer">
              <div className="inner">
                <div className="content">
                  {logData.city}, {logData.country}
                </div>
                <div className="content">
                  {logData.city}, {logData.country}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.userName}>{logData.userID}</div>
        <div className={styles.title}>{logData.title}</div>
        <div className={styles.article}>
          {logData.article}
          ​라일레이로 떠나는 날 저희는 가방을 숙소에 맡기고 나왔습니다. 아오낭 비치 근처를 돌아다니며 스노클링 장비도 사고 밥도 먹었어요. 숙소 가는
          길에 라일레이로 가는 롱테일보트의 매표소가 있어서, 저는 랑지에게 이 매표소에 잠깐 앉아 기다리라고 하고 맡겨둔 짐을 찾아 돌아왔습니다. 여기서
          살짝 문제가 생겼습니다. 롱테일 보트는 사람 8명이 차면 출발하는 방식입니다. 그런데 랑지가 매표소로 갔을 때, 이미 꽤 많은 사람들이 기다리고
          있는 상황이었어요. 매표소 직원이 랑지에게 어디로 가냐 물어서 라일레이로 간다고 했고, 일행 한 명이 가방을 가지러 숙소로 갔다고 했습니다. 20분
          정도 걸릴 거라고 말하고, 랑지는 저를 기다렸습니다. ​
        </div>
      </div>
    </div>
  );
};

const ListView = ({ logList }) => {
  return (
    <div className={styles.listView}>
      {logList.map((logData, index) => {
        return <ListItem logData={logData} key={index} />;
      })}
    </div>
  );
};
export default ListView;
