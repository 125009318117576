import React from "react";
import styles from "./Modal.module.css";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

const DeleteModal = ({ moveTo = "/", setModalData }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.loginModal}>
        삭제 후 복구할 수 없습니다. <br />
        그래도 삭제하시겠습니까?
      </div>
      <Button
        onClick={() => {
          navigate(moveTo);
          setModalData(null);
        }}
        isDestructive={true}
      >
        삭제하기
      </Button>
    </>
  );
};
export default DeleteModal;
