import React, { useState, useEffect } from "react";
import styles from "./Info.module.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { mapActions } from "../../../store/map-slice";
import BasicInput from "../../../components/BasicInput/BasicInput";
import { editorActions } from "../../../store/editor-slice";
import SearchModule from "./Modules/SearchModule";
import DateModule from "./Modules/DateModule";
import ColorModule from "./Modules/ColorModule";
import { postLocation, putLocation } from "../../../axios/editor";
import InteractiveBox from "../../../components/InteractiveBox/InteractiveBox";
import TextInput from "../../../components/TextInput/TextInput";

const Info = () => {
  const editorData = useSelector((state) => state.editor);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [warningMsg, setWarningMsg] = useState("");

  useEffect(() => {
    if (!editorData.status.isMarkerSet) {
      navigate("/editor/search", { replace: true });
    } else {
      console.log(editorData.markerPoint);
      dispatch(mapActions.setCenterZoom({ lat: editorData.markerPoint.lat, lng: editorData.markerPoint.lng, zoom: 15 }));
      dispatch(mapActions.lockMap());
    }
    return () => {
      dispatch(mapActions.unLockMap());
    };
  }, []);

  const privacyChangeHandler = (event) => {
    dispatch(editorActions.setPrivacy({ isPrivate: event.target.checked }));
  };

  const privacyLabelClickHandler = () => {
    dispatch(editorActions.flipPrivacy());
    return true;
  };

  const placeNameChangeHandler = (text) => {
    dispatch(editorActions.setPlaceName({ placeName: text }));
  };

  const nextClickHandler = async () => {
    if (!editorData.country.name_eng) {
      setWarningMsg("국가를 선택하세요");
    } else if (!editorData.city.name_eng) {
      setWarningMsg("도시를 선택하세요");
    } else if (!editorData.placeName) {
      setWarningMsg("장소 이름을 입력하세요");
    } else if (!editorData.year) {
      setWarningMsg("연도를 입력하세요");
    } else if (!editorData.month) {
      setWarningMsg("월을 입력하세요");
    } else {
      console.log(editorData);
      if (editorData.logID) {
        const result = await putLocation(
          editorData.logID,
          editorData.markerPoint.lat,
          editorData.markerPoint.lng,
          editorData.placeName,
          editorData.year,
          editorData.month,
          editorData.color,
          !editorData.isPrivate,
          editorData.country.name_eng,
          editorData.city.name_eng,
          editorData.country.country_id,
          editorData.city.city_id
        );
        if (result.isSuccess) {
          dispatch(editorActions.setLocationStatus());
          navigate("/editor/article");
        } else {
          setWarningMsg(result.message);
        }
      } else {
        const result = await postLocation(
          editorData.markerPoint.lat,
          editorData.markerPoint.lng,
          editorData.placeName,
          editorData.year,
          editorData.month,
          editorData.color,
          !editorData.isPrivate,
          editorData.country.name_eng,
          editorData.city.name_eng,
          editorData.country.country_id,
          editorData.city.city_id
        );
        console.log(result);
        if (result.isSuccess) {
          dispatch(editorActions.setLogID({ logID: result.logID }));
          dispatch(editorActions.setLocationStatus());
          navigate("/editor/article");
        } else {
          setWarningMsg(result.message);
        }
      }
    }
    setTimeout(() => {
      setWarningMsg("");
    }, 3000);
  };

  return (
    <div className="page-divided">
      <div className={`${styles.info} left`}>
        <InteractiveBox size={6}>
          <BasicInput>FOLDER</BasicInput>
          <SearchModule />
          <TextInput placeholder="PLACE NAME" onChange={placeNameChangeHandler} value={editorData.placeName} />
          <DateModule />
          <ColorModule />
        </InteractiveBox>
        <div className="warning">{warningMsg}</div>
        <Button onClick={nextClickHandler}>다음</Button>
      </div>
    </div>
  );
};
export default Info;
