import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListView from "../../components/ListView/ListView";
import { getRecent } from "../../axios/log";
import { logActions } from "../../store/log-slice";
import { mapActions } from "../../store/map-slice";

const Feed = () => {

  const dispatch = useDispatch();
  const logData = useSelector((state) => state.log);

  const getRecentLogList = async () => {
    const recentLogList = (await getRecent()).logList;
    const markerList = [];
    recentLogList.map((logData, idx) => {
      markerList.push({
        position: {
          lat: logData.lat,
          lng: logData.lng,
        },
        color: logData.color,
      });
    });
    dispatch(mapActions.setLogMarkers({ logMarkers: markerList }));
    dispatch(logActions.setRecentList({ recentList: recentLogList }));
  };

  useEffect(() => {
    if (!logData.recentList || logData.recentList.length === 0) {
      getRecentLogList();
    }
  }, []);

  return (
    <div className="page-divided">
      <div className="left">
        <ListView logList={logData.recentList} />
      </div>
    </div>
  );
};
export default Feed;
