import axios from 'axios'

const SERVER = "https://crouton.kr/map/";
// const SERVER = "http://localhost:8080/map/";

async function getPlace (keyword) {
    const res = await axios.get(
        SERVER + 'search',{
            params: {
                keyword
            }
        }
    )
    return res.data;
}  


export {getPlace}