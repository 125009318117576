import React from "react";
import styles from "./ColorDot.module.css";
import paletteImage from "../../assets/icon/palette.png";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "../../store/editor-slice";

const ColorDot = ({ onClick = () => {}, dotID = 0, isVisible = true, color, showImage = false }) => {
  const editorData = useSelector((state) => state.editor);
  const dispatch = useDispatch();
  const colorIndex = editorData.colorIndex;
  const onColorClick = () => {
    if (dotID < 0) {
    } else {
      dispatch(editorActions.setColorIndex({ colorIndex: dotID }));
    }
    onClick();
  };
  return (
    <div
      className={`${styles.colorDot} ${isVisible ? styles.visible : ""} ${dotID === colorIndex || dotID === -1 ? styles.activated : ""} ${
        dotID === -1 ? styles.centered : ""
      }`}
      style={{ backgroundColor: color }}
      onClick={onColorClick}
    >
      <div></div>
      {showImage && <img src={paletteImage} alt="" />}
    </div>
  );
};
export default ColorDot;
