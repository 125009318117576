const getDistance = (location1, location2) => {
  const deltaY = location1.lat - location2.lat;
  const deltaX = location1.lng - location2.lng;

  const distance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
  return distance;
};

const getZIndex = (location1, location2, index) => {
  const distance = getDistance(location1, location2);
  if (distance < 0.1) {
    // console.log("z-index, " + index + "/ " + Math.round((1 - distance) * 1000 + 20));
    return Math.round((1 - distance) * 1000 + 20);
  } else {
    return 20 - index;
  }
};
const getRandomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

export { getDistance, getZIndex, getRandomColor };
