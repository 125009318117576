import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  color: "#ED553B",
  colorIndex: 0,
  isPrivate: false,
  country: { name_eng: "", name_kor: "", country_id: 0 },
  city: { name_eng: "", name_kor: "", city_id: 0 },
  placeName: "",
  year: "",
  month: "",
  markerPoint: { lat: 0, lng: 0 },
  status: { isMarkerSet: false, isLocationSet: false },
  newLocation: {
    countryList: [],
    cityList: [],
    countryInput: "",
    cityInput: "",
  },
  images: [],
  deletedImageUrlList: [],
  imageIndex: 0,
  logID: null,
  title: "",
  article: "",
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    resetAll(state) {
      state.color = initialState.color;
      state.colorIndex = initialState.colorIndex;
      state.isPrivate = initialState.isPrivate;
      state.country = initialState.country;
      state.city = initialState.city;
      state.placeName = initialState.placeName;
      state.year = initialState.year;
      state.month = initialState.month;
      state.markerPoint = initialState.markerPoint;
      state.status = initialState.status;
      state.newLocation = initialState.newLocation;
      state.images = initialState.images;
      state.deletedImageUrlList = initialState.deletedImageUrlList;
      state.imageIndex = initialState.imageIndex;
      state.logID = initialState.logID;
      state.title = initialState.title;
      state.article = initialState.article;
    },
    setColor(state, action) {
      state.color = action.payload.color;
    },
    setColorIndex(state, action) {
      state.colorIndex = action.payload.colorIndex;
    },
    setPrivacy(state, action) {
      state.isPrivate = action.payload.isPrivate;
    },
    flipPrivacy(state) {
      state.isPrivate = !state.isPrivate;
    },
    setCountry(state, action) {
      state.country = action.payload.country;
    },
    setCity(state, action) {
      state.city = action.payload.city;
    },
    setCountryList(state, action) {
      state.newLocation.countryList = action.payload.countryList;
    },
    setCityList(state, action) {
      state.newLocation.cityList = action.payload.cityList;
    },
    setCountryInput(state, action) {
      state.newLocation.countryInput = action.payload.countryInput;
    },
    setCityInput(state, action) {
      state.newLocation.cityInput = action.payload.cityInput;
    },
    setYear(state, action) {
      state.year = action.payload.year;
    },
    setMonth(state, action) {
      state.month = action.payload.month;
    },
    setLatLng(state, action) {
      state.markerPoint.lat = action.payload.lat;
      state.markerPoint.lng = action.payload.lng;
    },
    setPlaceName(state, action) {
      state.placeName = action.payload.placeName;
    },
    setMarkerStatus(state) {
      state.status.isMarkerSet = true;
    },
    setLocationStatus(state) {
      state.status.isLocationSet = true;
    },
    setTitle(state, action) {
      state.title = action.payload.title;
    },
    setArticle(state, action) {
      state.article = action.payload.article;
    },
    addImage(state, action) {
      console.log("image added", action.payload)
      state.images.push({
        original: action.payload.image,
        cropped: action.payload.image,
        croppedAreaPixels: null,
        crop: { x: 0, y: 0 },
        zoom: 1,
        isChanged: false,
        url: null,
        index: Math.random().toString(),
      });
    },
    reorderImage(state, action) {
      console.log("image reordered", action.payload)
      state.images = action.payload.reorderedImage;
    },
    setImageIndex(state, action) {
      console.log("image index set", action.payload)
      state.imageIndex = action.payload.imageIndex;
    },
    removeImage(state, action) {
      console.log("image removed", action.payload)
      if (state.imageIndex >= action.payload.index && state.images.length !== 1) {
        state.imageIndex--;
      }
      if (state.images[action.payload.index].url) {
        state.deletedImageUrlList.push(state.images[action.payload.index].url);
      }
      state.images.splice(action.payload.index, 1);
    },
    cropImage(state, action) {
      console.log("image cropped", action.payload)
      state.images[action.payload.imageIndex].crop = action.payload.crop;
      state.images[action.payload.imageIndex].isChanged = true;
    },
    zoomImage(state, action) {
      console.log("image zoomed", action.payload)
      state.images[action.payload.imageIndex].zoom = action.payload.zoom;
      state.images[action.payload.imageIndex].isChanged = true;
    },
    setCroppedPixels(state, action) {
      console.log("image croppedpixels", action.payload)
      state.images[action.payload.imageIndex].croppedAreaPixels = action.payload.croppedAreaPixels;
      state.images[action.payload.imageIndex].isChanged = true;
    },
    setCroppedImage(state, action) {
      console.log("image croppedimage", action.payload)
      state.images[action.payload.imageIndex].cropped = action.payload.image;
      state.images[action.payload.imageIndex].isChanged = true;
    },
    setImageUploaded(state, action) {
      console.log("image uploaded", action.payload)
      let dataIndex = 0;
      state.images.map((image, index) => {
        if (image.isChanged) {
          state.images[index].url = action.payload.dataList[dataIndex];
          state.images[index].isChanged = false;
          dataIndex++;
        }
      });
      state.deletedImageUrlList = [];
    },
    setLogID(state, action) {
      state.logID = action.payload.logID;
    },
  },
});

export const editorActions = editorSlice.actions;

export default editorSlice.reducer;
