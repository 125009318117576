import axios from "axios";
import { dataUrIToFile } from "../utils/image";
import { silentRefresh } from "./auth";
import store from "../store";
import { editorActions } from "../store/editor-slice";

const SERVER = "https://crouton.kr/editor/";
// const SERVER = "http://localhost:8080/editor/";

const getCountryList = async (keyword) => {
  const data = (
    await axios.get(SERVER + "country-list", {
      params: {
        keyword,
      },
    })
  ).data;
  if (data.isSuccess) return data.countryList;
  else return [];
};

const getCityList = async (keyword, cid) => {
  console.log("keyword, cid", keyword, cid)
  const data = (
    await axios.get(SERVER + "city-list", {
      params: {
        keyword,
        cid,
      },
    })
  ).data;

  if (data.isSuccess) return data.cityList;
  else return [];
};

const postLocation = async (lat, lng, placeName, year, month, markerColor, isPublic, countryName, cityName, countryID, cityID) => {
  console.log("axios posted new Location");

  if (!(await silentRefresh())) return { isSuccess: false, message: "failed" };

  const res = await axios.post(SERVER + "location", {
    lat,
    lng,
    placeName,
    year,
    month,
    markerColor,
    isPublic,
    countryName,
    cityName,
    countryID,
    cityID,
  });
  console.log(res.data);
  return res.data;
};

const putLocation = async (logID, lat, lng, placeName, year, month, markerColor, isPublic, countryName, cityName, countryID, cityID) => {
  console.log("axios posted new Location");

  if (!(await silentRefresh())) return { isSuccess: false, message: "fail to refresh location" };

  const res = await axios.put(SERVER + "location", {
    logID,
    lat,
    lng,
    placeName,
    year,
    month,
    markerColor,
    isPublic,
    countryName,
    cityName,
    countryID,
    cityID,
  });
  return res.data;
};

const putTitle = async (title, logID) => {
  if (!(await silentRefresh())) return { isSuccess: false, message: "fail to refresh location" };

  const res = await axios.put(SERVER + "title", { title, logID });

  console.log(res);
  return res.data.isSuccess;
};

const putArticle = async (article, logID) => {
  if (!(await silentRefresh())) return { isSuccess: false, message: "fail to refresh location" };

  const res = await axios.put(SERVER + "article", { article, logID });
  console.log(res);
  return res.data.isSuccess;
};

const postPublish = async (logID) => {
  if (!(await silentRefresh())) return { isSuccess: false, message: "fail to refresh location" };

  const res = await axios.post(SERVER + "publish", { logID });
  console.log(res);
  return res.data.isSuccess;
};

const postImages = async () => {
  if (!(await silentRefresh())) return { isSuccess: false, message: "failed" };

  console.log("image posted")
  const formData = new FormData();
  let isNeverChanged = true;
  const changedImageUrlList = [];
  const { images, logID, deletedImageUrlList } = store.getState().editor;

  images.map((image, index) => {
    if (image.isChanged) {
      console.log("image is changed");
      if (image.url) changedImageUrlList.push(image.url);
      isNeverChanged = false;
      const file = dataUrIToFile(image.cropped, "title" + index + ".jpg");
      formData.append("images", file);
    }
  });

  if (isNeverChanged && deletedImageUrlList.length === 0) {
    console.log("nothing is changed");
    return true;
  }

  formData.append("logID", logID);
  formData.append("changedImageUrlList", changedImageUrlList);
  formData.append("deletedImageUrlList", deletedImageUrlList);

  const data = (
    await axios({
      method: "POST",
      url: SERVER + "images",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  ).data;
  console.log(data);

  if (data.isSuccess) {
    console.log(data.newImageUrlList);
    store.dispatch(editorActions.setImageUploaded({ dataList: data.newImageUrlList }));
    return true;
  } else {
    return false;
  }
};

export { getCountryList, getCityList, postLocation, putLocation, putTitle, putArticle, postPublish, postImages };
