import React, { useState, useEffect, useRef } from "react";
import styles from "./LetterBox.module.css";
import LetterList from "../../components/LetterList/LetterList";

const LetterBox = () => {
  const [letterDataList, setLetterDataList] = useState([]);
  const [isReceivedShowing, setRecievedShowing] = useState(true);

  useEffect(() => {
    (async () => {
      //TODO GET DATA FROM SERVER
      const tempLetterData = [
        {
          letterUrl: "/letter/UUID",
          senderName: "샹젤리제다니",
          senderID: "sun-w-danni",
          date: "2022. 11. 27 19:00",
          body: "안녕하세요, 헤이즈님. 노래 듣고 편지 보냅니다. 노래가 참 좋네요. 즐거운 하루 되세요. 라일레이로 떠나는 날, 저희는 가방을 숙소에 맡기고",
        },
        {
          letterUrl: "/letter/UUID",
          senderName: "샹젤리제다니",
          senderID: "sun-w-danni",
          date: "2022. 11. 27 19:00",
          body: "안녕하세요, 헤이즈님. 노래 듣고 편지 보냅니다. 노래가 참 좋네요. 즐거운 하루 되세요. 라일레이로 떠나는 날, 저희는 가방을 숙소에 맡기고",
        },
        {
          letterUrl: "/letter/UUID",
          senderName: "샹젤리제다니",
          senderID: "sun-w-danni",
          date: "2022. 11. 27 19:00",
          body: "안녕하세요, 헤이즈님. 노래 듣고 편지 보냅니다. 노래가 참 좋네요. 즐거운 하루 되세요. 라일레이로 떠나는 날, 저희는 가방을 숙소에 맡기고",
        },
        {
          letterUrl: "/letter/UUID",
          senderName: "샹젤리제다니",
          senderID: "sun-w-danni",
          date: "2022. 11. 27 19:00",
          body: "안녕하세요, 헤이즈님. 노래 듣고 편지 보냅니다. 노래가 참 좋네요. 즐거운 하루 되세요. 라일레이로 떠나는 날, 저희는 가방을 숙소에 맡기고",
        },
      ];
      setLetterDataList(tempLetterData);
    })();
  }, []);

  const logToggleClickHandler = () => {
    setRecievedShowing(true);
  };

  const savedToggleClickHandler = () => {
    setRecievedShowing(false);
  };

  return (
    <div className={styles.letter}>
      <div className={styles.toggle}>
        <div
          className={`${isReceivedShowing && styles.selected}`}
          onClick={logToggleClickHandler}
        >
          받은 편지(11)
        </div>
        <div
          className={`${!isReceivedShowing && styles.selected}`}
          onClick={savedToggleClickHandler}
        >
          보낸 편지(10)
        </div>
      </div>
      <div className={styles.letterList}>
        <LetterList letterDataList={letterDataList} />
      </div>
    </div>
  );
};
export default LetterBox;
