import React, { useState, useEffect } from "react";
import LocationSearchResult from "../../../../components/LocationSearchResult/LocationSearchResult";
import TextInput from "../../../../components/TextInput/TextInput";
import { ReactComponent as ArrowBottomImg } from "../../../../assets/icon/arrow-bottom.svg";
import styles from "../Info.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCityList, getCountryList } from "../../../../axios/editor";
import { editorActions } from "../../../../store/editor-slice";
import SearchResult from "./SearchResultModule";

const SearchModule = () => {
  const editorData = useSelector((state) => state.editor);
  const dispatch = useDispatch();

  const [isCountryResultVisible, setCountryResultVisible] = useState(false);
  const [isCityResultVisible, setCityResultVisible] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      searchCountry();
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [editorData.newLocation.countryInput]);

  useEffect(() => {
    const timer = setTimeout(() => {
      searchCity();
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [editorData.newLocation.cityInput]);

  const searchCountry = async () => {
    const result = await getCountryList(editorData.newLocation.countryInput);
    if (getCustomOptionVisible(result, editorData.newLocation.countryInput)) {
      console.log("add runs");
      result.unshift({ name_eng: editorData.newLocation.countryInput, name_kor: "직접입력" });
    }
    setCountryList(result);
  };

  const searchCity = async () => {
    console.log("country", editorData.country)
    const result = await getCityList(editorData.newLocation.cityInput, editorData.country.country_id);
    console.log(result);
    if (getCustomOptionVisible(result, editorData.newLocation.cityInput)) {
      result.unshift({ name_eng: editorData.newLocation.cityInput, name_kor: "직접입력" });
    }
    setCityList(result);
  };

  const countryChangeHandler = (text) => {
    dispatch(editorActions.setCountryInput({ countryInput: text.toUpperCase() }));
  };

  const cityChangeHandler = (text) => {
    dispatch(editorActions.setCityInput({ cityInput: text.toUpperCase() }));
  };

  const countryFocusHandler = () => {
    searchCountry();
    setCountryResultVisible(true);
  };

  const cityFocusHandler = () => {
    searchCity();
    setCityResultVisible(true);
  };

  const countryResultToggleClickHandler = () => {
    setCountryResultVisible((prev) => !prev);
    setCityResultVisible(false);
  };

  const cityResultToggleClickHandler = () => {
    setCityResultVisible((prev) => !prev);
    setCountryResultVisible(false);
  };

  const countrySelectHandler = (data) => {
    dispatch(editorActions.setCountry({ country: data }));
    console.log(data);
    dispatch(editorActions.setCountryInput({ countryInput: data.name_eng }));
    dispatch(editorActions.setCity({ city: { name_eng: "", name_kor: "", city_id: 0 } }));
    dispatch(editorActions.setCityInput({ cityInput: "" }));
    setCountryResultVisible(false);
  };

  const citySelectHandler = (data) => {
    dispatch(editorActions.setCity({ city: data }));
    dispatch(editorActions.setCityInput({ cityInput: data.name_eng }));
    setCityResultVisible(false);
  };

  const getCustomOptionVisible = (list, keyword) => {
    let isCustomOptionVisible = true;
    if (keyword === "") {
      isCustomOptionVisible = false;
    }

    list.map((item) => {
      console.log(item.name_eng, keyword);
      if (item.name_eng === keyword || item.name_kor === keyword) {
        isCustomOptionVisible = false;
      }
    });
    return isCustomOptionVisible;
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <TextInput placeholder="COUNTRY" onFocus={countryFocusHandler} onChange={countryChangeHandler} value={editorData.newLocation.countryInput} />

        <div className={`${styles.toggleButton} ${isCountryResultVisible ? styles.open : ""}`} onClick={countryResultToggleClickHandler}>
          <ArrowBottomImg />
        </div>
      </div>
      <div className={`${styles.countryResult} ${isCountryResultVisible ? styles.visible : ""}`}>
        {countryList.map((data, index) => {
          return <SearchResult data={data} key={index} onClick={countrySelectHandler} />;
        })}
      </div>
      <div className={styles.inputContainer}>
        <TextInput placeholder="CITY" onFocus={cityFocusHandler} value={editorData.newLocation.cityInput} onChange={cityChangeHandler} />
        <div className={`${styles.toggleButton} ${isCityResultVisible ? styles.open : ""}`} onClick={cityResultToggleClickHandler}>
          <ArrowBottomImg />
        </div>
      </div>
      <div className={`${styles.cityResult} ${isCityResultVisible ? styles.visible : ""}`}>
        {cityList.map((data, index) => {
          return <SearchResult data={data} key={index} onClick={citySelectHandler} />;
        })}
      </div>
    </>
  );
};

export default SearchModule;
