import React, { useState, useEffect, useCallback } from "react";
import styles from "./Profile.module.css";
import Button from "../../components/Button/Button";
import warning from "../../utils/warning";
// import cameraImage from "../../assets/camera.svg";
// import deleteImage from "../../assets/cancel.svg";
import Cropper from "react-easy-crop";
import { Link } from "react-router-dom";
import { getCroppedImg, readFile } from "../../utils/image";
import { forceRefresh, postProfile, silentRefresh } from "../../axios/auth";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { genImageUrl, genProfileUrl } from "../../utils/url";
import TextInput from "../../components/TextInput/TextInput";

const Profile = () => {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [userName, setUserName] = useState("");
  const [isImageChanged, setImageChanged] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");

  const userData = useSelector((state) => state.auth);

  useEffect(() => {
    setUserName(userData.userName);
    setCroppedImage(genProfileUrl(userData.profileImage));
  }, []);

  const userNameInputHandler = (event) => {
    warning.warnNameWrong(event.target.value, warningMsg, setWarningMsg);
    setUserName(event.target.value);
  };

  const deleteClickHandler = () => {
    setCroppedImage(null);
    setImageChanged(true);
  };

  const nextClickHandler = async (event) => {
    event.preventDefault();

    if (warning.warnNameWrong(event.target.value, warningMsg, setWarningMsg)) return;
    if (userName === userData.userName && !isImageChanged) {
      navigate("/archive");
      return;
    }

    const isImageDeleted = userData.profile && !croppedImage;

    const result = await postProfile(userName, croppedImage, isImageDeleted);
    console.log(result);
    if (result.isSuccess) {
      await forceRefresh();
      navigate("/archive");
    } else {
      setWarningMsg(result.message);
    }
  };

  const imageUploadHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      setImageChanged(true);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImage);
      setImageSrc(null);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const editClickedHandler = (event) => {
    event.preventDefault();
    showCroppedImage();
  };

  return (
    <div>
      {imageSrc ? (
        <div className={styles.interactiveBoxContainer}>
          <div className={styles.cropperWrapper}>
            <div className={styles.cropper}>
              <Cropper
                cropShape="round"
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          </div>
          <Button isHighlighted={true} onClick={editClickedHandler}>
            편집 완료
          </Button>
        </div>
      ) : (
        <div className={styles.interactiveBoxContainer}>
          <div className={styles.interactiveBox}>
            <div className={`${styles.profile}`}>
              <div className={styles.imageContainer}>
                <div className={styles.image}>
                  <label className={styles.button} htmlFor="img-file">
                    {/* {croppedImage ? <img className={styles.croppedImage} src={croppedImage} alt="" /> : <img src={cameraImage} alt="" />} */}
                  </label>
                  <input type="file" name="file" id="img-file" onChange={imageUploadHandler} accept="image/*" />
                  <div className={`${styles.delete} ${userData.profileImage || croppedImage ? styles.visible : ""}`} onClick={deleteClickHandler}>
                    {/* <img src={deleteImage} alt="" /> */}
                  </div>
                </div>
              </div>
              <TextInput type="text" placeholder="USERNAME" onChange={userNameInputHandler} value={userName} />
            </div>
          </div>
          <div className={styles.warning}>{warningMsg}</div>
          <Button isDisabled={false} onClick={nextClickHandler}>
            수정
          </Button>
          <Link to="/archive" className={styles.cancel}>
            취소
          </Link>
        </div>
      )}
    </div>
  );
};
export default Profile;
