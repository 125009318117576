import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Control from "../../components/Control/Control";
import LoginModal from "../../components/Modals/LoginModal";
import ReportModal from "../../components/Modals/ReportModal";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import SquareButton from "../../components/SquareButton/SquareButton";
import { ReactComponent as CloseImg } from "../../assets/icon/sq-close.svg";
import { ReactComponent as PlaceImg } from "../../assets/icon/icon-location-tr.svg";

import { ReactComponent as ExpandImg } from "../../assets/icon/sq-expand.svg";
import { ReactComponent as CopyImg } from "../../assets/icon/sq-copy.svg";
import { ReactComponent as ReportImg } from "../../assets/icon/sq-report.svg";
import { ReactComponent as CommentImg } from "../../assets/icon/sq-comment-full.svg";
import { ReactComponent as LikeImg } from "../../assets/icon/sq-like-full.svg";

import { logAddressParser } from "../../utils/location";
import { getLogDetail } from "../../axios/log";
import { uiActions } from "../../store/ui-slice";

// import { ReactComponent as CopyImage } from "../../assets/copy.svg";
// import maximizeImage from "../../assets/maximize.svg";
// import minimizeImage from "../../assets/minimize.svg";
// import dftProfileImage from "../../assets/profile.jpg";
// import cancelImage from "../../assets/cancel.svg";
// import { ReactComponent as BookmarkImage } from "../../assets/bookmark.svg";
// import menuImage from "../../assets/menu.svg";
// import reportImage from "../../assets/report.svg";

import styles from "./Log.module.css";
import { logActions } from "../../store/log-slice";
import { genImageUrl } from "../../utils/url";
import Slide from "../../components/Slide/Slide";
import { mapActions } from "../../store/map-slice";
import { Title, Content, Controller } from "../../components/ArticleLayout/ArticleLayout";

const Log = ({}) => {
  const logData = useSelector((state) => state.log).detail;
  const [isBookmarked, setBookmarked] = useState(false);
  const [isMaximized, setMaximized] = useState(false);
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(30);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (logData.logID === 0 || logData.logID !== locationData.logID) {
      getLogInfo(locationData.userID, locationData.logID);
    }
    return () => {
      // setFullScreen(false);
    };
  }, []);

  const subscribeClickHandler = () => {
    dispatch(
      uiActions.setModal({
        modalData: {
          type: "login",
        },
      })
    );
  };

  const locationData = logAddressParser(useLocation().pathname);

  const getLogInfo = async (userID, logID) => {
    console.log("getLoginfo runs");
    const data = await getLogDetail(userID, logID);
    if (data.isSuccess) {
      const detail = data.detail;
      dispatch(logActions.setDetail({ detail }));
      dispatch(mapActions.setCenterZoom({ lat: detail.lat, lng: detail.lng, zoom: 13 }));
    } else {
      // TODO 잘못된 접근입니다. ALERT & 다른 곳으로 보내기
    }
  };

  const menuToggleClickHandler = () => {
    setMenuOpened((prev) => !prev);
  };

  const linkCopyClickHandler = () => {
    const text = window.location.href;
    navigator.clipboard.writeText(text);
  };

  const reportClickHandler = () => {
    dispatch(
      uiActions.setModal({
        modal: <ReportModal />,
      })
    );
  };

  const bookmarkClickHandler = () => {
    setBookmarked(!isBookmarked);
  };
  const fullScreenClickHandler = () => {
    setMaximized((prev) => !prev);
    // setFullScreen((prev) => !prev);
  };
  const cancelClickHandler = () => {
    navigate(-1);
  };

  const likeClickHandler = () => {
    setLiked((prev) => {
      if (prev) {
        setLikeCount((prev) => prev - 1);
      } else {
        setLikeCount((prev) => prev + 1);
      }
      return !prev;
    });
  };

  return (
    <div className="page-divided">
      <div className="left">
        <div className={styles.infoContainer}>
          <div className={styles.imageContainer}>
            <Slide imageList={logData.imageList.map((value) => genImageUrl(value))} />
          </div>
          <div className={styles.authorContainer}>
            <div className={styles.profileContainer}>
              <div className={styles.profile}>
                <ProfileImage color="var(--crt-bg-100)"></ProfileImage>
              </div>
              <div>
                <div className={styles.userName}>{logData.userName}</div>
                <div className={styles.userID}>@{logData.userID}</div>
              </div>
            </div>

            <SquareButton onClick={subscribeClickHandler} btnText="구독" bdColor="var(--crt-blue)" color="var(--crt-blue)"></SquareButton>
          </div>
        </div>
        <div className={styles.locationContainer}>
          <div className={styles.country}>{logData.country}</div>
          <div className={styles.cityContainer}>
            <div className={styles.marker} style={{ backgroundColor: logData.color }}></div>
            <div>{logData.city}</div>
          </div>
          <div>
            {logData.year}. {logData.month}
          </div>
        </div>
      </div>
      <div className="right">
        <Title CloseButtonImg={CloseImg} onClose={cancelClickHandler}>
          <div>{logData.title}</div>
        </Title>
        <Content>
          <div className={styles.placeContainer}>
            <div className={styles.place}>
              <PlaceImg stroke="var(--crt-fg-100)" />
              {logData.placeName}
            </div>
            <div className={styles.date}>2022. 12. 23.</div>
          </div>
          <div className={styles.article}>{logData.article}</div>
        </Content>
        <Controller>
          <div className={`${styles.controller} ${styles.left}`}>
            <SquareButton onClick={menuToggleClickHandler}>
              <ExpandImg stroke="var(--crt-fg-100)" className={`${styles.toggleButton} ${isMenuOpened && styles.opened}`} />
            </SquareButton>

            <div className={`${styles.controllerMenuContainer} ${isMenuOpened && styles.opened}`}>
              <div className={styles.controllerMenu}>
                <SquareButton onClick={linkCopyClickHandler} textOnChange="COPIED">
                  <CopyImg />
                </SquareButton>
                <SquareButton onClick={reportClickHandler}>
                  <ReportImg />
                </SquareButton>
              </div>
            </div>
          </div>
          <div className={`${styles.controller} ${styles.right}`}>
            <div className={styles.socialButton}>
              <CommentImg />
              <div>3</div>
            </div>
            <div className={`${styles.socialButton} ${styles.likeButton}`} onClick={likeClickHandler}>
              <LikeImg fill={liked ? "var(--crt-blue)" : "transparent"} />
              <div>{likeCount}</div>
            </div>
          </div>
        </Controller>
      </div>
    </div>
  );
};
export default Log;
