import React, { useState } from "react";
import ButtonInput from "../../../components/ButtonInput/ButtonInput";
import Button from "../../../components/Button/Button";
import InteractiveBox from "../../../components/InteractiveBox/InteractiveBox";
import styles from "./Terms.module.css";
import { useNavigate } from "react-router-dom";
import warning from "../../../utils/warning";
import privacy from "../../../config/privacy";
import terms from "../../../config/terms";

const Terms = () => {
  const consentBtnTextList = ["확인하기", "동의하기", "동의완료"];
  const [ppStatus, setPPStatus] = useState(0);
  const [tcStatus, setTCStatus] = useState(0);
  const [warningMsg, setWarningMsg] = useState("");

  const navigate = useNavigate();

  const ppClickHandler = (event) => {
    setPPStatus((prev) => (prev < 2 ? prev + 1 : prev - 1));
  };

  const tcClickHandler = (event) => {
    setTCStatus((prev) => (prev < 2 ? prev + 1 : prev - 1));
  };

  const nextClickHandler = (event) => {
    event.preventDefault();
    if (warning.warnPPDisagreed(ppStatus, warningMsg, setWarningMsg) && warning.warnTCDisagreed(tcStatus, warningMsg, setWarningMsg)) {
      navigate("/join/info", { state: { ppStatus, tcStatus } });
    }
  };

  return (
    <div className="page-full center">
      <InteractiveBox>
        <ButtonInput textList={consentBtnTextList} onClick={ppClickHandler} status={ppStatus}>
          <div className={styles.label}>
            서비스 이용 약관
          </div>
        </ButtonInput>
        <div className={`${styles.content} ${styles.pp} ${ppStatus === 1 ? styles.unfold : ""}`}>
          <div>{terms}</div>
        </div>
        <ButtonInput textList={consentBtnTextList} onClick={tcClickHandler} status={tcStatus}>
          <div className={styles.label}>
            개인정보 처리방침
          </div>
        </ButtonInput>
        <div className={`${styles.content} ${styles.tc} ${tcStatus === 1 ? styles.unfold : ""}`}>{privacy}</div>
      </InteractiveBox>
      <div className="warning">{warningMsg}</div>
      <Button isDisabled={ppStatus + tcStatus < 4} onClick={nextClickHandler}>
        다음
      </Button>
    </div>
  );
};
export default Terms;
