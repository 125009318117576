import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { uiActions } from "../../store/ui-slice";

import styles from "./Modal.module.css";
import { ReactComponent as CloseImg } from "../../assets/icon/btn-close.svg";
import LoginModal from "./LoginModal";
import { useNavigate } from "react-router-dom";

const Modal = () => {
  const modalData = useSelector((state) => state.ui.modalData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalVisible, setModalVisible] = useState(false);

  console.log("this runs");

  const close = () => {
    
    dispatch(uiActions.removeModal());
  };

  let modalBody = null;

  switch (modalData && modalData.type) {
    case "login":
      modalBody = (
        <>
          <div className={styles.text}>
            로그인 후 이용해보세요. <br /> 로그인 하시겠습니까?
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.button} onClick={close}>
              취소
            </div>
            <div
              className={`${styles.button} ${styles.positive}`}
              onClick={() => {
                navigate("/login");
                close();
              }}
            >
              로그인
            </div>
          </div>
        </>
      ); 
      break;

    default:
      break;
  }

  return (
    <div>
      <div
        className={`${styles.modalContainer} ${isModalVisible && styles.pop}`}
        onClick={() => {
          dispatch(uiActions.removeModal());
        }}
      >
        <div
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {modalBody}

          <div
            className={styles.exitModal}
            onClick={() => {
              dispatch(uiActions.removeModal());
            }}
          >
            <CloseImg width={20} stroke="var(--crt-fg-100)" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
