import React from "react";
import styles from "./TextInput.module.css";

const TextInput = ({ type = "text", placeholder = "", onChange = () => {}, onEnter = () => {}, onFocus = () => {}, isDisabled = false, value = "", isLast = false }) => {
  const _onChange = (event) => {
    onChange(event.target.value);
  };

  const _onEnter = (event) => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  return (
    <input
      type={type}
      placeholder={placeholder}
      className={`${styles.input} ${isDisabled ? styles.disabled : ""} ${isLast ? "last" : ""}`}
      onChange={_onChange}
      onKeyDown={_onEnter}
      disabled={isDisabled}
      onFocus={onFocus}
      value={value}
    />
  );
};
export default TextInput;
