import React, { useState, useEffect } from "react";
import styles from "../Search.module.css";
// import searchImg from "../../../assets/search.svg";
import { useDispatch } from "react-redux";
import { mapActions } from "../../../store/map-slice";
import { useNavigate } from "react-router-dom";
import { genImageUrl } from "../../../utils/url";

const GridItem = ({ logData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const markerClickHandler = (event) => {
    event.stopPropagation();
    dispatch(mapActions.setCenter({ center: { lat: logData.lat, lng: logData.lng } }));
  };

  const LogClickHandler = () => {
    navigate(`/log/${logData.userID}/${logData.logID}`);
  };

  return (
    <div className={styles.gridItem} onClick={LogClickHandler}>
      <img src={genImageUrl(logData.thumb)} alt="" />
      <div className={styles.marker} style={{ backgroundColor: logData.color }} onClick={markerClickHandler}></div>
      <div className={styles.info}>
        <div className={styles.title}>{logData.title}</div>
        <div className={styles.location}>
          {logData.city}, {logData.country}
        </div>
      </div>
    </div>
  );
};
export default GridItem;
