import React, { useState, useEffect } from "react";
import LocationSearchResult from "../../../../components/LocationSearchResult/LocationSearchResult";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import styles from "../Info.module.css";
import { useDispatch, useSelector } from "react-redux";
import validation from "../../../../config/validation";
import { editorActions } from "../../../../store/editor-slice";
import TextInput from "../../../../components/TextInput/TextInput";

const DateModule = () => {
  const editorData = useSelector((state) => state.editor);
  const dispatch = useDispatch();

  const yearChangeHandler = (text) => {
    const filteredText = validation.filterNumber(text, 4);
    dispatch(editorActions.setYear({ year: filteredText }));
  };

  const monthChangeHandler = (text) => {
    const filteredText = validation.filterMonth(text);
    dispatch(editorActions.setMonth({ month: filteredText }));
  };

  return (
    <div className={styles.dualInputContainer}>
      <TextInput type="number" placeholder="YEAR" onChange={yearChangeHandler} value={editorData.year} />
      <TextInput type="number" placeholder="MONTH" onChange={monthChangeHandler} value={editorData.month}/>
    </div>
  );
};

export default DateModule;
