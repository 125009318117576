const defaultImage = require("../assets/icon/icon-user.svg");
// import { ReactComponent as WriteIcon } from "../assets/icon/icon-user.svg";
// // assets/icon/icon-user.svg";

export const genImageUrl = (imageUrl) => {
  return `https://crouton-img.s3.ap-northeast-2.amazonaws.com/${imageUrl}`;
};

export const genProfileUrl = (profileUrl) => {
  if (profileUrl) return ``;
  // else return defaultImage;
};
