const logAddressParser = (pathname = "") => {
  const pathList = pathname.split("/");
  console.log("userID", pathList[2], "LOGID", pathList[3]);
  return { userID: pathList[2], logID: pathList[3] };
};

const archiveAddressParser = (pathname = "") => {
  const pathList = pathname.split("/");
  return { userID: pathList[2] };
};

export { logAddressParser, archiveAddressParser };
