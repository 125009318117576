import axios from "axios";
import store from "../store";
import { authActions } from "../store/auth-slice";

const checkTokenBroken = () => {
  return new Date(store.getState().auth.tokenExpires) < new Date() || store.getState().auth.isLogin === false;
};

const setLogin = (data) => {
  axios.defaults.headers.common["authorization"] = `Bearer ${data.accessToken}`;
  console.log(data);
  store.dispatch(
    authActions.setLogin({
      userID: data.userID,
      userNo: data.userNo,
      tokenExpires: data.expiresAt,
      userName: data.userName,
      profileImage: data.profileImage,
    })
  );
};

const setLogout = () => {
  store.dispatch(authActions.setLogout());
};

export { checkTokenBroken, setLogin, setLogout };
