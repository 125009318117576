import React, { useEffect } from "react";
import styles from "./Map.module.css";
import { GoogleMap, Marker, OverlayView, OverlayViewF, useJsApiLoader } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import SearchMarker from "./Modules/SearchMarkerModule";
import WriteMarker from "./Modules/WriteMarkerModule";
import { mapActions } from "../../store/map-slice";
import { ReactComponent as MarkerImg } from "../../assets/icon/marker.svg";
import markerImg from "../../assets/icon/marker.svg";

const Map = () => {
  const mapData = useSelector((state) => state.map);
  const editorData = useSelector((state) => state.editor);
  const dispatch = useDispatch();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDqPyE03JfS0n5p_h-qhH7SVUmVTtaBTcI",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const mapDragHandler = () => {
    if (map) {
      const center = { lat: map.center.lat(), lng: map.center.lng() };
      dispatch(mapActions.setCenter({ center }));
    }
  };

  useEffect(() => {
    if (map) {
      map.panTo(mapData.center);
    }
  }, [mapData.center]);

  return isLoaded ? (
    <div className={styles.mapContainer}>
      <GoogleMap
        center={mapData.center}
        zoom={mapData.zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          mapId: "d2fc05b3dda8bc5a",
          disableDefaultUI: true,
          fullscreenControl: false,
          mapTypeControl: false,
          clickableIcons: false,
          draggableCursor: "var(--cursor-draggable)",
          draggingCursor: "var(--cursor-dragging)",
        }}
        clickableIcons={false}
        mapContainerClassName={styles.map}
        onDragEnd={mapDragHandler}
      >
        {mapData.logMarkers.map((marker, idx) => {
          console.log(marker);
          return (
            <Marker
              position={marker.position}
              icon={{
                // url: markerImg,

                path:
                  `
                    m -10, 0, 
                    a 10, 10 0 1, 1 20,0 
                    a 10, 10 0 1, 1 -20, 0`,
                fillColor: marker.color,
                strokeColor: '#fbfbfb',
                fillOpacity: 1,
                strokeWeight: 2,
                scaledSize: new window.google.maps.Size(20, 20),
              }}
            />
          );
        })}
        {mapData.searchResultMarkers.map((data, index) => {
          return (
            <OverlayView key={index} position={data.position} mapPaneName={OverlayView.FLOAT_PANE}>
              <SearchMarker position={data.position} idx={data.index}></SearchMarker>
            </OverlayView>
          );
        })}
      </GoogleMap>
      <div className={styles.centerWriteMarker}>
        <WriteMarker isVisible={mapData.centerMarkerVisible} fixed={true}></WriteMarker>
      </div>
      <div className={`${styles.lock} ${mapData.isLocked ? styles.visible : ""}`}>
        <MarkerImg fill={editorData.color}></MarkerImg>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Map;
