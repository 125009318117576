import logo from "./assets/icon/crouton-logo.svg";
import { silentRefresh } from "./axios/auth";

import { Route, Routes, Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Map from "./pages/Map/Map";
import Login from "./pages/Login/Login";
import Feed from "./pages/Feed/Feed";
import JoinTerms from "./pages/Join/Terms/Terms";
import JoinInfo from "./pages/Join/Info/Info";
import JoinWelcome from "./pages/Join/Welcome/Welcome";
import Password from "./pages/Password/Password";
import Log from "./pages/Log/Log";
import Archive from "./pages/Archive/Archive";
import LogImage from "./pages/LogImage/LogImage";
import NavList from "./components/NavList/NavList";
import styles from "./App.module.css";
import Modal from "./components/Modals/Modal";
import LetterBox from "./pages/LetterBox/LetterBox";
import LetterDetail from "./pages/LetterDetail/LetterDetail";
import NewLetter from "./pages/NewLetter/NewLetter";

import EditorTemp from "./pages/Editor/Temp/Temp";
import EditorSearch from "./pages/Editor/Search/Search";
import EditorInfo from "./pages/Editor/Info/Info";
import EditorArticle from "./pages/Editor/Article/Article";
import EditorImage from "./pages/Editor/Image/Image";

import Search from "./pages/Search/Search";
import Profile from "./pages/Profile/Profile";

function App() {
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    silentRefresh();
  }, []);

  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <Link to="/">
          <img className={styles.logo} src={logo} alt="logo" />
        </Link>
        <NavList currentPath={currentPath}></NavList>
      </header>
      <div className={styles.container}>
        <Map isFullScreen={currentPath === "map"}></Map>
        <TransitionGroup className={styles.transition}>
          <CSSTransition key={location.pathname} classNames={"fade"} timeout={200}>
            <Routes location={location}>
              <Route path="/" element={<Feed />} />
              <Route path="/login/*" element={<Login />} />
              <Route path="/join/terms" element={<JoinTerms />} />
              <Route path="/join/info" element={<JoinInfo />} />
              <Route path="/join/welcome" element={<JoinWelcome />} />
              <Route path="/find-password" element={<Password />} />
              <Route path="/search" element={<Search />} />
              <Route path="/log/*" element={<Log />} />

              <Route path="/editor/temp" element={<EditorTemp />} />
              <Route path="/editor/search" element={<EditorSearch />} />
              <Route path="/editor/info" element={<EditorInfo />} />
              <Route path="/editor/article" element={<EditorArticle />} />
              <Route path="/editor/image" element={<EditorImage />} />
              

              <Route path="/archive/*" element={<Archive />} />
              <Route path="/letter/box" element={<LetterBox />} />
              <Route path="/letter/new" element={<NewLetter />} />
              <Route path="/letter/view/*" element={<LetterDetail />} />
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
        <Modal />
      </div>
    </div>
  );
}

export default App;
